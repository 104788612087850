import React, { useState, useEffect, useRef } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import {
  Grid,
  Paper,
  List,
  ListItem,
  ListItemText,
  TextField,
  Button,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  IconButton,
  CircularProgress,
  Typography,
} from "@material-ui/core";
import { Delete } from "@material-ui/icons";

// monaco
import Editor from "@monaco-editor/react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";

const EditCodePage = ({ uid, toast, socketRef }) => {
  const [serverSideScript, setServerSideScript] = useState({});
  const [selectedGame, setSelectedGame] = useState("");
  const [selectedCode, setSelectedCode] = useState("");
  const [code, setCode] = useState("");
  const [newGameDialogOpen, setNewGameDialogOpen] = useState(false);
  const [newGameName, setNewGameName] = useState("");
  const [newCodeDialogOpen, setNewCodeDialogOpen] = useState(false);
  const [newCodeName, setNewCodeName] = useState("");
  const [deleteGameDialogOpen, setDeleteGameDialogOpen] = useState(false);
  const [deleteCodeDialogOpen, setDeleteCodeDialogOpen] = useState(false);
  const [loading, setLoading] = useState(false);
  const editorRef = useRef(null);

  useEffect(() => {
    if (uid) {
      const serverSideScriptsRef = firebase
        .database()
        .ref(`users/${uid}/serverSideScripts`);

      const handleData = (snapshot) => {
        const data = snapshot.val() || {};
        setServerSideScript(data);

        if (!(selectedGame in data) && Object.keys(data).length > 0) {
          setSelectedGame(Object.keys(data)[0]);
        }
      };

      serverSideScriptsRef.on("value", handleData);

      return () => {
        serverSideScriptsRef.off("value", handleData);
      };
    } else {
      console.error("User ID not found while reading data.");
    }
  }, []);

  // Handle selectedGame changes
  useEffect(() => {
    if (uid) {
      const data = Object.assign({}, serverSideScript);

      if (selectedGame && data[selectedGame]) {
        const codeTitles = Object.keys(data[selectedGame]);

        if (codeTitles.length > 0) {
          //always select the first script
          toast("Please select a script!");
        } else {
          toast("Please create a script");
        }
      } else {
      }
      setSelectedCode("");
      setCode("");

      setLoading(false);
    }
  }, [selectedGame]);

  // Handle selectedCode changes
  useEffect(() => {
    if (uid) {
      const data = Object.assign({}, serverSideScript);

      if (selectedGame && selectedCode) {
        if (selectedCode in data[selectedGame]) {
          const codeContent = data[selectedGame][selectedCode].savedCode || "";
          setCode(codeContent);
        } else {
          setCode("");
        }
      }

      setLoading(false);
    }
  }, [selectedCode]);

  function updateSavedCode(newCode) {
    firebase
      .database()
      .ref(
        `users/${uid}/serverSideScripts/${selectedGame}/${selectedCode}/savedCode`
      )
      .set(newCode);
  }

  const handleEditorChange = (newCode) => {
    setCode(newCode);

    if (uid && selectedGame && selectedCode) {
      updateSavedCode(newCode);
    }
  };
  const handleListItemClick = (gameOrCode, isGame) => {
    if (isGame) {
      if (gameOrCode == selectedGame) {
        toast("Already selected this game!");
      } else {
        setSelectedGame(gameOrCode);
        setLoading(true);
      }
    } else {
      if (gameOrCode == selectedCode) {
        toast("Already selected this game!");
      } else {
        setSelectedCode(gameOrCode);
        setLoading(true);
      }
    }
  };

  const handleNewGame = () => {
    setNewGameDialogOpen(true);
  };

  const handleConfirmNewGame = () => {
    if (uid && newGameName) {
      firebase
        .database()
        .ref(`users/${uid}/serverSideScripts/${newGameName}`)
        .set({
          step: {
            savedCode: "",
            deployedCode: "",
          },
        });

      setNewGameDialogOpen(false);
      setNewGameName("");
    }
  };

  const handleCancelNewGame = () => {
    setNewGameDialogOpen(false);
    setNewGameName("");
  };

  const handleNewCode = () => {
    setNewCodeDialogOpen(true);
  };

  const handleConfirmNewCode = () => {
    if (uid && selectedGame && newCodeName) {
      firebase
        .database()
        .ref(`users/${uid}/serverSideScripts/${selectedGame}/${newCodeName}`)
        .set({
          savedCode: "",
          deployedCode: "",
        });

      setNewCodeDialogOpen(false);
      setNewCodeName("");
    }
  };

  const handleConfirmDeleteCode = () => {
    if (selectedGame && selectedCode) {
      firebase
        .database()
        .ref(`users/${uid}/serverSideScripts/${selectedGame}/${selectedCode}`)
        .remove();
    }
    setDeleteCodeDialogOpen(false);
  };

  const handleEditorDidMount = (editor, monaco) => {
    editorRef.current = editor;
  };

  function handleFormatCode() {
    // trigger the format selection action
    editorRef.current.trigger("editor", "editor.action.formatDocument");
  }
  const handleDeployCode = async () => {
    setLoading(true);
    if (uid && selectedGame && selectedCode) {
      firebase
        .database()
        .ref(
          `users/${uid}/serverSideScripts/${selectedGame}/${selectedCode}/deployedCode`
        )
        .set(code);
    }
    try {
      socketRef.current.send(
        JSON.stringify({
          eventName: "streamer_deploy_ssc",
          game: selectedGame,
          script: selectedCode,
          ssc: code,
        })
      );
      toast("Requested the server to change the Server Side Code!");
    } catch (error) {
      toast.error("Deployment failed: " + error.message);
    } finally {
      setLoading(false);
    }
  };
  return (
    <div>
      <Grid container spacing={2} style={{ height: "100%", padding: "16px" }}>
        <Grid item xs={12} sm={4}>
          <Paper
            style={{
              height: "100%",
              overflow: "auto",
              border: "1px solid black",
            }}
          >
            <List
              style={{
                marginLeft: "10px",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: "16px",
                  marginLeft: "10px",
                }}
                variant="h6"
                gutterBottom
              >
                Your Games
              </Typography>
              {Object.keys(serverSideScript).map((game) => (
                <ListItem
                  key={game}
                  button
                  selected={selectedGame === game}
                  onClick={() => handleListItemClick(game, true)}
                  style={{
                    borderRadius: "8px",
                    margin: "4px 0",
                    display: "flex",
                    justifyContent: "center",
                    width: "240px", // Adjusted width

                    backgroundColor:
                      selectedGame === game ? "#e3f2fd" : "inherit",
                    border: selectedGame === game ? "2px solid blue" : "none",
                  }}
                >
                  <ListItemText primary={game} />
                  <IconButton
                    onClick={() => {
                      setDeleteGameDialogOpen(true);
                    }}
                  >
                    <Delete />
                  </IconButton>
                </ListItem>
              ))}
              <Button
                variant="contained"
                color="primary"
                onClick={handleNewGame}
                style={{ margin: "8px" }}
              >
                Add New Game
              </Button>
            </List>

            <Paper
              style={{
                height: "auto",
                overflow: "auto",
                width: "80%",
                border: "1px solid black",
                marginLeft: "20px",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: "10px",
                  marginLeft: "10px",
                }}
                variant="h6"
                gutterBottom
              >
                Scripts in this game
              </Typography>
              <List>
                {selectedGame && serverSideScript[selectedGame]
                  ? Object.keys(serverSideScript[selectedGame]).map((code) => (
                      <ListItem
                        key={code}
                        button
                        selected={selectedCode === code}
                        onClick={() => handleListItemClick(code, false)}
                        style={{
                          borderRadius: "8px",
                          margin: "4px 0",
                          display: "flex",
                          justifyContent: "center",
                          width: "240px", // Adjusted width

                          backgroundColor:
                            selectedCode === code ? "#e3f2fd" : "inherit",
                          border:
                            selectedCode === code ? "2px solid blue" : "none",
                        }}
                      >
                        <ListItemText primary={code} />
                        <IconButton
                          onClick={() => {
                            setDeleteCodeDialogOpen(true);
                          }}
                        >
                          <Delete />
                        </IconButton>
                      </ListItem>
                    ))
                  : null}
                <Button
                  variant="contained"
                  color="primary"
                  onClick={handleNewCode}
                  style={{ margin: "8px" }}
                >
                  Add New Script
                </Button>
              </List>
            </Paper>
          </Paper>
        </Grid>

        <Grid item xs={12} sm={8}>
          <Paper style={{ height: "70vh", overflow: "auto" }}>
            {loading ? (
              <div
                style={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "center",
                  height: "50vh",
                }}
              >
                <CircularProgress />
              </div>
            ) : (
              <Editor
                style={{ height: "70vh" }}
                defaultLanguage="javascript"
                value={code}
                onChange={handleEditorChange}
                // options={{
                //   automaticLayout: true,
                //   formatOnType: true,
                //   formatOnPaste: true,
                // }}
                onMount={handleEditorDidMount}
              />
            )}
          </Paper>
        </Grid>

        <Grid item xs={12} sm={4}></Grid>

        <Grid item>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={handleFormatCode}
            disabled={loading}
          >
            Format
          </Button>
          <Button
            variant="contained"
            color="primary"
            style={{ marginLeft: "10px" }}
            onClick={handleDeployCode}
            disabled={loading}
          >
            Deploy
          </Button>
        </Grid>

        {/* New Game Dialog */}
        <Dialog open={newGameDialogOpen} onClose={handleCancelNewGame}>
          <DialogTitle>Add New Game</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="newGameName"
              label="Game Name"
              type="text"
              fullWidth
              value={newGameName}
              onChange={(e) => setNewGameName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCancelNewGame} color="primary">
              Cancel
            </Button>
            <Button onClick={handleConfirmNewGame} color="primary">
              Add Game
            </Button>
          </DialogActions>
        </Dialog>

        {/* New Code Dialog */}
        <Dialog
          open={newCodeDialogOpen}
          onClose={() => {
            setNewCodeDialogOpen(false);
            setNewCodeName("");
          }}
        >
          <DialogTitle>Add New Code</DialogTitle>
          <DialogContent>
            <TextField
              autoFocus
              margin="dense"
              id="newCodeName"
              label="Code Name"
              type="text"
              fullWidth
              value={newCodeName}
              onChange={(e) => setNewCodeName(e.target.value)}
            />
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setNewCodeDialogOpen(false);
                setNewCodeName("");
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleConfirmNewCode} color="primary">
              Add Code
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Game Confirmation Dialog */}
        <Dialog
          open={deleteGameDialogOpen}
          onClose={() => {
            setDeleteGameDialogOpen(false);
          }}
        >
          <DialogTitle>Confirm Delete Game</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the selected game?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDeleteGameDialogOpen(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button
              onClick={() => {
                if (selectedGame) {
                  firebase
                    .database()
                    .ref(`users/${uid}/serverSideScripts/${selectedGame}`)
                    .remove();
                }
                setDeleteGameDialogOpen(false);
              }}
              color="primary"
            >
              Delete
            </Button>
          </DialogActions>
        </Dialog>

        {/* Delete Code Confirmation Dialog */}
        <Dialog
          open={deleteCodeDialogOpen}
          onClose={() => {
            setDeleteCodeDialogOpen(false);
          }}
        >
          <DialogTitle>Confirm Delete Code</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Are you sure you want to delete the selected code?
            </DialogContentText>
          </DialogContent>
          <DialogActions>
            <Button
              onClick={() => {
                setDeleteCodeDialogOpen(false);
              }}
              color="primary"
            >
              Cancel
            </Button>
            <Button onClick={handleConfirmDeleteCode} color="primary">
              Delete
            </Button>
          </DialogActions>
        </Dialog>
      </Grid>
    </div>
  );
};

export default EditCodePage;
