import { Order } from "blockly/javascript";
import * as Blockly from "blockly";
// Export all the code generators for our custom blocks,
// but don't register them with Blockly yet.
// This file has no side effects!
export const forBlock = Object.create(null);

forBlock["add_text"] = function (block, generator) {
  const text = generator.valueToCode(block, "TEXT", Order.NONE) || "''";
  const color =
    generator.valueToCode(block, "COLOR", Order.ATOMIC) || "'#ffffff'";

  const addText = generator.provideFunction_(
    "addText",
    `function ${generator.FUNCTION_NAME_PLACEHOLDER_}(text, color) {

  // Add text to the output area.
  const outputDiv = document.getElementById('output');
  const textEl = document.createElement('p');
  textEl.innerText = text;
  textEl.style.color = color;
  outputDiv.appendChild(textEl);
}`
  );
  // Generate the function call for this block.
  const code = `${addText}(${text}, ${color});\n`;
  return code;
};

// Generator for 'create_empty_object' block
forBlock["create_empty_object"] = function (block, generator) {
  return [" {}", Order.ATOMIC];
};
forBlock["create_object"] = function (block, generator) {
  var code = "{";
  var pairsBlock = block.getInputTargetBlock("PAIRS");
  while (pairsBlock) {
    var key = pairsBlock.getFieldValue("KEY");
    var value =
      generator.valueToCode(pairsBlock, "VALUE", Order.NONE) || "null";
    code += `${key}: ${value}, `;
    pairsBlock = pairsBlock.getNextBlock();
  }
  if (code.length > 1) {
    code = code.slice(0, -2); // Remove the last comma and space
  }
  code += "}";
  return [code, Order.ATOMIC];
};

forBlock["object_pair"] = function (block, generator) {
  // This block is used only for its values in the create_object generator
  return null;
};

// Generator for 'add_to_object' block
forBlock["add_to_object"] = function (block, generator) {
  const object = generator.valueToCode(block, "OBJECT", Order.NONE);
  const key = generator.valueToCode(block, "KEY", Order.ATOMIC) || "''";
  const value = generator.valueToCode(block, "VALUE", Order.ATOMIC) || "''";
  return object + "[" + key + "] = " + value + ";\n";
};

// Generator for 'in_object' block
forBlock["in_object"] = function (block, generator) {
  const object = generator.valueToCode(block, "OBJECT", Order.NONE);
  const key = generator.valueToCode(block, "KEY", Order.ATOMIC) || "''";
  return [object + "[" + key + "]", Order.ATOMIC];
};
// // Generator for 'variables_set' block
// forBlock["variables_set"] = function (block, generator) {
//   const variable = block.getFieldValue(block, "VAR", "item");
//   const value = generator.valueToCode(block, "VALUE", Order.ASSIGNMENT) || "''";
//   return `${variable} = ${value};\n`;
// };
// Generator for 'get_keys_from_object' block
forBlock["get_keys_from_object"] = function (block, generator) {
  const object = generator.valueToCode(block, "OBJECT", Order.NONE);
  return [`${object} ? Object.keys(${object}) : []`, Order.ATOMIC];
};

// Generator for 'get_value_of_key' block
// forBlock["get_value_of_key"] = function (block, generator) {
//   const object = generator.valueToCode(block, "OBJECT", Order.NONE);
//   const key = generator.valueToCode(block, "KEY", Order.ATOMIC) || "''";
//   return `${object}[${key}]`;
// };

// Generator for 'delete_key' block
forBlock["delete_key"] = function (block, generator) {
  const object = generator.valueToCode(block, "OBJECT", Order.NONE);
  const key = generator.valueToCode(block, "KEY", Order.ATOMIC) || "''";
  return "delete " + object + "[" + key + "];\n";
};

forBlock["for_key_in_object"] = function (block, generator) {
  const keyVariableName = generator.getVariableName(
    block.getFieldValue("KEYYYY")
  );

  const object = generator.valueToCode(block, "OBJECT", Order.NONE);
  const statements = generator.statementToCode(block, "STATEMENTS");

  return `for (const ${keyVariableName} in ${object}) {\n${statements}}\n`;
};

//important rnet constants
forBlock["get_game_constant"] = function (block, generator) {
  return ["game", Order.ATOMIC];
};

// Generator for 'get_room_constant' block
forBlock["get_room_constant"] = function (block, generator) {
  return ["room", Order.ATOMIC];
};

// Generator for 'get_client_constant' block
forBlock["get_client_constant"] = function (block, generator) {
  return ["client", Order.ATOMIC];
};

forBlock["get_entity_constant"] = function (block, generator) {
  return ["entity", Order.ATOMIC];
};

forBlock["get_persistent_object_constant"] = function (block, generator) {
  return ["persistentObject", Order.ATOMIC];
};

forBlock["get_event_name_constant"] = function (block, generator) {
  return ["eventName", Order.ATOMIC];
};
forBlock["get_message_struct_constant"] = function (block, generator) {
  return ["messageStruct", Order.ATOMIC];
};
forBlock["get_sender_client_id_constant"] = function (block, generator) {
  return ["senderClientId", Order.ATOMIC];
};

// Generator for 'step_block' statement block
forBlock["step_block"] = function (block, generator) {
  const statements = generator.statementToCode(block, "STATEMENTS");
  return `function step() {
${statements}
}
step()\n`;
};

// Generator for 'client_sent_event_block' statement block
forBlock["client_sent_event_block"] = function (block, generator) {
  const statements = generator.statementToCode(block, "STATEMENTS");
  const eventName = block.getFieldValue("EVENT_NAME");
  const messageStruct = block.getFieldValue("MESSAGE_STRUCT");

  return `function client_sent_event(senderClientId, eventName, messageStruct) {
${statements}
}
client_sent_event(senderClientId, eventName, messageStruct) //this line makes sure that the function we defined is actually called
\n`;
};

// Generator for 'game_server_created_block' statement block
forBlock["game_server_created_block"] = function (block, generator) {
  const statements = generator.statementToCode(block, "STATEMENTS");
  return `function game_server_created() {
${statements}
}
game_server_created()\n`;
};

// Generator for 'room_created_block' statement block
forBlock["room_created_block"] = function (block, generator) {
  const statements = generator.statementToCode(block, "STATEMENTS");
  return `function room_created() {
${statements}
}
room_created()\n`;
};

// Generator for 'client_created_block' statement block
forBlock["client_created_block"] = function (block, generator) {
  const statements = generator.statementToCode(block, "STATEMENTS");
  return `function client_created() {
${statements}
}
client_created()\n`;
};

// Generator for 'entity_created_block' statement block
forBlock["entity_created_block"] = function (block, generator) {
  const statements = generator.statementToCode(block, "STATEMENTS");
  return `function entity_created() {
${statements}
}
entity_created()\n`;
};

// Generator for 'persistent_object_created_block' statement block
forBlock["persistent_object_created_block"] = function (block, generator) {
  const statements = generator.statementToCode(block, "STATEMENTS");
  return `function persistent_object_created() {
${statements}
}
persistent_object_created()\n`;
};

// for rooms in game
forBlock["for_rooms_in_game"] = function (block, generator) {
  const keyVariableName = generator.getVariableName(
    block.getFieldValue("ROOM_ID")
  );

  const object = generator.valueToCode(block, "OBJECT", Order.NONE);
  const statements = generator.statementToCode(block, "STATEMENTS");

  return `for (const ${keyVariableName} in ${object}.rooms) {\n${statements}}\n`;
};

// for CLIENTS in room
forBlock["for_clients_in_room"] = function (block, generator) {
  const keyVariableName = generator.getVariableName(
    block.getFieldValue("CLIENT_ID")
  );

  const object = generator.valueToCode(block, "OBJECT", Order.NONE);
  const statements = generator.statementToCode(block, "STATEMENTS");

  return `for (const ${keyVariableName} in ${object}.clients) {\n${statements}}\n`;
};

// for CLIENTS in room
forBlock["for_persistent_objects_in_room"] = function (block, generator) {
  const keyVariableName = generator.getVariableName(
    block.getFieldValue("PERSISTENT_OBJECT_ID")
  );

  const object = generator.valueToCode(block, "OBJECT", Order.NONE);
  const statements = generator.statementToCode(block, "STATEMENTS");

  return `for (const ${keyVariableName} in ${object}.persistentObjects) {\n${statements}}\n`;
};
// for ENTITIES in client
forBlock["for_entities_in_client"] = function (block, generator) {
  const keyVariableName = generator.getVariableName(
    block.getFieldValue("ENTITY_ID")
  );

  const object = generator.valueToCode(block, "OBJECT", Order.NONE);
  const statements = generator.statementToCode(block, "STATEMENTS");

  return `for (const ${keyVariableName} in ${object}.entities) {\n${statements}}\n`;
};

//get room from roomid
forBlock["get_room"] = function (block, generator) {
  const roomId = generator.valueToCode(block, "ROOM_ID", Order.NONE); //this is how to get input values

  var code = `game.rooms[${roomId}]`;
  return [code, Order.ATOMIC];
};

//get client from client id
forBlock["get_client"] = function (block, generator) {
  const roomId = generator.valueToCode(block, "ROOM_ID", Order.NONE); //this is how to get input values
  const clientId = generator.valueToCode(block, "CLIENT_ID", Order.NONE); //this is how to get input values

  var code = `game.rooms[${roomId}].clients[${clientId}]`;
  return [code, Order.ATOMIC];
};

//get client from client id
forBlock["get_entity"] = function (block, generator) {
  const roomId = generator.valueToCode(block, "ROOM_ID", Order.NONE); //this is how to get input values
  const clientId = generator.valueToCode(block, "CLIENT_ID", Order.NONE); //this is how to get input values
  const entityId = generator.valueToCode(block, "ENTITY_ID", Order.NONE); //this is how to get input values

  var code = `game.rooms[${roomId}].clients[${clientId}].entities[${entityId}]`;
  return [code, Order.ATOMIC];
};

//get PO
forBlock["get_persistent_object"] = function (block, generator) {
  const roomId = generator.valueToCode(block, "ROOM_ID", Order.NONE); //this is how to get input values
  const poId = generator.valueToCode(block, "PERSISTENT_OBJECT_ID", Order.NONE); //this is how to get input values

  var code = `game.rooms[${roomId}].persistentObjects[${poId}]`;
  return [code, Order.ATOMIC];
};

forBlock["get_sp_method"] = function (block, generator) {
  const variable = generator.valueToCode(block, "VARIABLE", Order.NONE); //this is how to get input values

  var code = `${variable}.getSP()`;
  return [code, Order.ATOMIC];
};
forBlock["get_sps_method"] = function (block, generator) {
  const variable = generator.valueToCode(block, "VARIABLE", Order.NONE); //this is how to get input values

  var code = `${variable}.getSPfromServer()`;
  return [code, Order.ATOMIC];
};

forBlock["set_sp"] = function (block, generator) {
  var client = generator.valueToCode(block, "CLIENT", Order.ATOMIC);
  var newSp = generator.valueToCode(block, "NEW_SP", Order.ATOMIC);
  var code = `${client}.setSP(${newSp});\n`;
  return code;
};

forBlock["set_sps"] = function (block, generator) {
  var client = generator.valueToCode(block, "CLIENT", Order.ATOMIC);
  var newSps = generator.valueToCode(block, "NEW_SPS", Order.ATOMIC);
  var code = `${client}.setSP(${newSps});\n`;
  return code;
};

// Generator for 'add_to_object' block
forBlock["edit_object"] = function (block, generator) {
  const object = generator.valueToCode(block, "OBJECT", Order.NONE);
  const key = generator.valueToCode(block, "KEY", Order.ATOMIC) || "''";
  const value = generator.valueToCode(block, "VALUE", Order.ATOMIC) || "''";
  return object + "[" + key + "] = " + value + ";\n";
};

forBlock["get_ep_method"] = function (block, generator) {
  const variable = generator.valueToCode(block, "VARIABLE", Order.NONE); //this is how to get input values

  var code = `${variable}.getEP()`;
  return [code, Order.ATOMIC];
};
forBlock["get_eps_method"] = function (block, generator) {
  const variable = generator.valueToCode(block, "VARIABLE", Order.NONE); //this is how to get input values

  var code = `${variable}.getEPfromServer()`;
  return [code, Order.ATOMIC];
};

forBlock["set_ep"] = function (block, generator) {
  var entity = generator.valueToCode(block, "ENTITY", Order.ATOMIC);
  var newEp = generator.valueToCode(block, "NEW_EP", Order.ATOMIC);
  var code = `${entity}.setEP(${newEp});\n`;
  return code;
};

forBlock["set_eps"] = function (block, generator) {
  var entity = generator.valueToCode(block, "ENTITY", Order.ATOMIC);
  var newEps = generator.valueToCode(block, "NEW_EPS", Order.ATOMIC);
  var code = `${entity}.setSP(${newEps});\n`;
  return code;
};

//POP
forBlock["create_po"] = function (block, generator) {
  const POP = generator.valueToCode(block, "POP", Order.NONE); //this is how to get input values
  const room = generator.valueToCode(block, "ROOM", Order.NONE); //this is how to get input values

  var code = `new PersistentObject(${POP},${room})`;
  return [code, Order.ATOMIC];
};
forBlock["get_pop"] = function (block, generator) {
  const variable = generator.valueToCode(block, "VARIABLE", Order.NONE); //this is how to get input values

  var code = `${variable}.getProperties()`;
  return [code, Order.ATOMIC];
};
forBlock["set_pop"] = function (block, generator) {
  const variable = generator.valueToCode(block, "VARIABLE", Order.NONE); //this is how to get input values
  const new_pop = generator.valueToCode(block, "NEW_POP", Order.NONE); //this is how to get input values

  var code = `${variable}.setProperties(${new_pop})`;
  return [code, Order.ATOMIC];
};
forBlock["destroy_po"] = function (block, generator) {
  const variable = generator.valueToCode(block, "VARIABLE", Order.NONE); //this is how to get input values

  var code = `${variable}.destroy()`;
  return [code, Order.ATOMIC];
};

forBlock["get_data_from_db"] = function (block, generator) {
  var collectionName =
    generator.valueToCode(block, "COLLECTION_NAME", Order.ATOMIC) || '""';
  var documentName =
    generator.valueToCode(block, "DOCUMENT_NAME", Order.ATOMIC) || '""';

  var code = `game.getSimpleData(${collectionName}, ${documentName}).then((response) => {\n`;
  code += `  console.log(response);\n`;
  code += `});\n`;
  return code;
};
forBlock["get_data_from_db"] = function (block, generator) {
  var collectionName =
    generator.valueToCode(block, "COLLECTION_NAME", Order.ATOMIC) || '""';
  var documentName =
    generator.valueToCode(block, "DOCUMENT_NAME", Order.ATOMIC) || '""';
  const response = generator.getVariableName(block.getFieldValue("RESPONSE"));
  var branch = generator.statementToCode(block, "DO");

  var code = `game.getSimpleData(${collectionName}, ${documentName}).then((${response}) => {\n`;
  code += branch;
  code += `});\n`;
  return code;
};
forBlock["set_data_in_db"] = function (block, generator) {
  var collectionName =
    generator.valueToCode(block, "COLLECTION_NAME", Order.ATOMIC) || '""';
  var documentName =
    generator.valueToCode(block, "DOCUMENT_NAME", Order.ATOMIC) || '""';
  var document = generator.valueToCode(block, "DOCUMENT", Order.ATOMIC) || "{}";

  var code = `game.setSimpleData(${collectionName}, ${documentName}, ${document})`;
  return code;
};

forBlock["patch_data_in_db"] = function (block, generator) {
  var collectionName =
    generator.valueToCode(block, "COLLECTION_NAME", Order.ATOMIC) || '""';
  var documentName =
    generator.valueToCode(block, "DOCUMENT_NAME", Order.ATOMIC) || '""';
  var document = generator.valueToCode(block, "DOCUMENT", Order.ATOMIC) || "{}";

  var code = `game.patchSimpleData(${collectionName}, ${documentName}, ${document})`;
  return code;
};

forBlock["delete_data_in_db"] = function (block, generator) {
  var collectionName =
    generator.valueToCode(block, "COLLECTION_NAME", Order.ATOMIC) || '""';
  var documentName =
    generator.valueToCode(block, "DOCUMENT_NAME", Order.ATOMIC) || '""';

  var code = `game.deleteSimpleData(${collectionName}, ${documentName})`;
  return code;
};

forBlock["print"] = function (block, generator) {
  var value = generator.valueToCode(block, "PRINT_VALUE", Order.ATOMIC) || "''";
  return `console.log(${value});\n`;
};

//setcfs
forBlock["setcfs"] = function (block, generator) {
  var client = generator.valueToCode(block, "CLIENT", Order.ATOMIC);
  var eventName = generator.valueToCode(block, "EVENT_NAME", Order.ATOMIC);
  var messageStruct = generator.valueToCode(
    block,
    "MESSAGE_STRUCT",
    Order.ATOMIC
  );
  var code = `game.SendEventToClientFromServer(${client}.clientId, ${eventName}, ${messageStruct});\n`;
  return code;
};
