import { useLocation, history } from "react-router-dom";
import firebase from "firebase/compat/app";
import "firebase/compat/database";
import "firebase/compat/auth";
import { useEffect, useState, useRef, useLayoutEffect } from "react";
import ReactJson from "react-json-view";
import axios from "axios";
import IDE from "./IDE";
import {
  Card,
  CardContent,
  Typography,
  Button,
  Container,
  TextField,
  IconButton,
  Tooltip,
  Checkbox,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogContentText,
  DialogActions,
  Slider,
  Switch,
  FormControlLabel,
  TableCell,
  TableRow,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  ListItemSecondaryAction,
  CircularProgress,
} from "@material-ui/core";
import { TreeItem, TreeView } from "@material-ui/lab";
import FileCopyIcon from "@material-ui/icons/FileCopy";
import CheckCircleIcon from "@material-ui/icons/CheckCircle";
import DeleteIcon from "@material-ui/icons/Delete";
import { v4 as uuidv4 } from "uuid";
import { useNavigate } from "react-router-dom";
//import react hot toast
import toast, { Toaster } from "react-hot-toast";
import ClipboardJS from "clipboard";
//monaco
import Editor from "@monaco-editor/react";
import * as monaco from "monaco-editor/esm/vs/editor/editor.api";
const auth = firebase.auth();
const discordOAuthUrl =
  "https://discord.com/oauth2/authorize?client_id=1211409963268972664&response_type=code&redirect_uri=https%3A%2F%2Fdashboard.rocketnetworking.net%2Fdashboard&scope=guilds";
const sampleInitialCode = `
// Room iteration

for (let gameId in server.games){

switch(gameId){
  case "default":
  const game = server.games[gameId]
  for (let roomId in game.rooms) {
    const room = game.rooms[roomId];

 
    
    // Room-specific code here
    
    // Client iteration
    for (let clientId in room.clients) {
      const client = room.clients[clientId];
      
      // Client-specific code here

      //console.log("found client " + clientId + " in room " + roomId);
 
    }
  }
  break;

  //add more cases here for other games you have
}
}

`;
const planName = [
  "Free",
  "Vector⚡",
  "Momentum💡",
  "Spacetime⭐✨",
  "Escape Velocity🚀",
];
function getCurrentTimeWithSeconds() {
  const now = new Date();
  let hours = now.getHours();
  let minutes = now.getMinutes();
  let seconds = now.getSeconds();
  const ampm = hours >= 12 ? "PM" : "AM";

  // Convert to 12-hour format
  hours = hours % 12 || 12;

  // Ensure two-digit format for minutes and seconds
  minutes = minutes < 10 ? "0" + minutes : minutes;
  seconds = seconds < 10 ? "0" + seconds : seconds;

  // Construct the time string
  const currentTime = `${hours}:${minutes}:${seconds}${ampm}`;

  return currentTime;
}
function useQuery() {
  return new URLSearchParams(useLocation().search);
}

function ButtonWithLink() {
  return (
    <Button
      variant="contained"
      color="primary"
      href="https://billing.stripe.com/p/login/28obM9dsldhN0wgcMM"
      target="_blank"
    >
      Your Billing Portal / Edit Plan💳
    </Button>
  );
}

function RestartServerButton({ uid }) {
  return (
    <Button
      variant="contained"
      //green color
      style={{ backgroundColor: "#4caf50", color: "white" }}
      onClick={() => {
        if (window.confirm("Are you sure you want to restart the server?")) {
          axios
            .post(
              "https://us-central1-rocket-networking.cloudfunctions.net/api/restartServer",
              {
                uuid: uid,
              }
            )
            .then(() => alert("Server Restarted"))
            .catch(() => alert("Server Restart Failed"));
        }
      }}
    >
      Restart Server
    </Button>
  );
}
function ViewLogsButton({ uid, setShowLogs }) {
  return (
    <Button
      variant="contained"
      //green color
      style={{ backgroundColor: "#4caf50", color: "white" }}
      onClick={() => {
        toast("Getting Logs from your serverURL..", {
          icon: "🪵",
          style: {
            borderRadius: "10px",
            background: "#333",
            color: "#fff",
          },
        });
        setShowLogs(true);
      }}
    >
      View Logs
    </Button>
  );
}
const MainCard = ({
  uid,
  data,
  plan,
  setData,
  setShowLogs,
  serverIds,
  setServerIds,
  setOpenEditCodeDialog,
  setOpenServerVersionDialog,
}) => {
  const [jsonViewVisible, setJsonViewVisible] = useState(false);

  const handleToggleJsonView = () => {
    setJsonViewVisible(!jsonViewVisible);
  };

  return (
    <Card
      elevation={3}
      style={{
        width: "70%",
        margin: "1% auto",
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        borderRadius: "20px",
        padding: "20px",
        background: "linear-gradient(45deg, #E491F2,#FFF9A6 )", // Gradient from yellow to pink
      }}
    >
      <CardContent>
        <Typography
          variant="h4"
          align="center"
          gutterBottom
          style={{
            fontWeight: "bold",
            fontSize: "2.5rem", // Adjust the size as needed
            textTransform: "uppercase", // Uppercase the text
            letterSpacing: "2px", // Add letter spacing for emphasis
            margin: "20px 0", // Adjust margin for spacing
            borderBottom: "4px solid #FF69B4", // Add a pink (or your preferred color) underline
            padding: "10px 0", // Add padding for spacing
          }}
        >
          Your Dashboard 🚀
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            gap: "2vh",
            alignItems: "center",
            justifyContent: "center",
            marginBottom: "16px",
          }}
        >
          {/* <Typography variant="h5" gutterBottom>
            Secret Key: {uid}
          </Typography>
          <IconButton onClick={handleCopyKey} color="primary">
            <FileCopyIcon />
          </IconButton> */}
          <ServerIdManagement
            serverIds={serverIds}
            setServerIds={setServerIds}
            uid={uid}
          />
          <Button
            variant="contained"
            color="primary"
            onClick={() => setOpenEditCodeDialog(true)}
          >
            Open Server Side Code Editor
          </Button>

          <Button variant="contained" color="primary" href={discordOAuthUrl}>
            Discord Integration Manager
          </Button>
          {plan !== 0 && (
            <Button
              variant="contained"
              color="primary"
              onClick={() => setOpenServerVersionDialog(true)}
            >
              Manage Server Version
            </Button>
          )}
        </div>

        <FormControlLabel
          control={
            <Switch checked={jsonViewVisible} onChange={handleToggleJsonView} />
          }
          label="Show Server Activity"
          style={{ marginBottom: "16px" }}
        />

        {jsonViewVisible && (
          <div style={{ marginBottom: "20px" }}>
            <ReactJson src={data} theme="sola" />
          </div>
        )}

        <Typography variant="h5" gutterBottom>
          Your Plan: {planName[data?.plan]}
        </Typography>

        <div
          style={{
            display: "flex",
            // justifyContent: "center",
            // alignItems: "center",
            gap: "20px",
            marginBottom: "16px",
          }}
        >
          <Typography variant="h5" gutterBottom>
            Your Server Region: {data?.heroku.region}
          </Typography>
          {plan !== 0 && (
            <TextField
              id="outlined-select-currency-native"
              select
              label="Select"
              value={data?.heroku.region}
              onChange={(e) => {
                // Your existing code for region change
              }}
              SelectProps={{
                native: true,
              }}
              helperText="Edit to change your region"
              variant="outlined"
            >
              {["us", "eu"].map((option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              ))}
            </TextField>
          )}
        </div>

        <Typography variant="h5" gutterBottom>
          Max CCU Allowed: {data?.maxClients}
        </Typography>

        <Typography variant="h6" gutterBottom>
          Plan changes can take up to 10 mins to reflect.
        </Typography>

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            alignItems: "center",
            marginTop: "20px",
            gap: "20px",
          }}
        >
          <ButtonWithLink />
          <div style={{ display: "flex", flexDirection: "row", gap: "20px" }}>
            <RestartServerButton uid={uid} />
            <ViewLogsButton uid={uid} setShowLogs={setShowLogs} />
          </div>
          <Button
            variant="contained"
            color="secondary"
            onClick={() => {
              if (window.confirm("Are you sure you want to logout?")) {
                auth
                  .signOut()
                  .then(() => {
                    // Sign-out successful.
                    window.location.href = "/";
                  })
                  .catch((error) => {
                    // An error happened.
                    console.error("Logout error:", error);
                  });
              }
            }}
          >
            Logout
          </Button>
        </div>
      </CardContent>
    </Card>
  );
};
const CustomerDataOverview = ({ customerId }) => {
  const [subcollections, setSubcollections] = useState([]);
  const [selectedDocument, setSelectedDocument] = useState(null);
  const [openDialog, setOpenDialog] = useState(false);

  useEffect(() => {
    const fetchSubcollections = async () => {
      const rURL =
        "https://us-central1-rocket-networking.cloudfunctions.net/api/database/metadata";
      const headers = {
        Authorization: customerId,
      };

      try {
        const response = await axios.get(rURL, { headers });

        if (response.status === 200) {
          const fetchedSubcollections = response.data || [];

          setSubcollections(fetchedSubcollections);
        } else {
          toast.error("Error in getting Database View");
        }
      } catch (error) {
        console.error("Error:", error);
        // Handle the error as needed
      }
    };

    fetchSubcollections();
  }, [customerId]);

  const handleDocumentClick = async (subcollectionId, documentName) => {
    try {
      const rURL = `https://us-central1-rocket-networking.cloudfunctions.net/api/database/${subcollectionId}/${documentName}`;
      const headers = {
        Authorization: customerId,
      };

      const response = await axios.get(rURL, { headers });

      if (response.status === 200) {
        const selectedDocumentData = response.data || {};
        setSelectedDocument(selectedDocumentData);
        setOpenDialog(true);
        toast("Got document data!", {
          icon: "👏",
        });
      } else {
        toast.error("Error in getting Document View");
      }
    } catch (error) {
      console.error("Error:", error);
      // Handle the error as needed
    }
  };

  const handleCloseDialog = () => {
    setSelectedDocument(null);
    setOpenDialog(false);
  };

  return (
    <div>
      <TreeView
        defaultCollapseIcon={<span>-</span>}
        defaultExpandIcon={<span>+</span>}
      >
        {subcollections.map((subcollection) => (
          <TreeItem
            key={subcollection.id}
            nodeId={subcollection.id}
            label={subcollection.name}
          >
            {subcollection.documents.map((document) => (
              <TreeItem
                key={document.name}
                nodeId={document.name}
                label={document.name}
                onClick={() =>
                  handleDocumentClick(subcollection.name, document.name)
                }
              />
            ))}
          </TreeItem>
        ))}
      </TreeView>

      {/* Display selected document details in a dialog */}
      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>
          {selectedDocument ? selectedDocument.name : ""}
        </DialogTitle>
        <DialogContent>
          {selectedDocument && (
            <>
              <DialogContentText>
                {/* Display document properties here */}
                {JSON.stringify(selectedDocument)}
              </DialogContentText>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Close
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};

// New ReferralCard component
const ReferralCard = ({ promoCode, timesRedeemed }) => {
  const inputRef = useRef(null);

  const handleCopyClick = () => {
    const clipboard = new ClipboardJS(".copy-button", {
      target: () => inputRef.current,
    });

    clipboard.on("success", function (e) {
      e.clearSelection();
      // Toast for success
      // You can replace this with your toast implementation
      toast.success("Promo code copied to clipboard!");
    });

    clipboard.on("error", function (e) {
      console.error(e);
      // Toast for error
      // You can replace this with your toast implementation
      toast.error("Failed to copy promo code!");
    });

    // Trigger the click event on the hidden input for copying
    document.getElementById("promoCodeInput").click();
  };

  return (
    promoCode.length != 0 && (
      <Card
        elevation={3}
        style={{
          marginTop: "10%",
          marginRight: "2%",
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
          borderRadius: "20px",
          textAlign: "center", // Center text horizontally
        }}
      >
        <CardContent>
          <Typography
            style={{
              fontWeight: "bold",
            }}
            variant="h5"
            align="center"
            gutterBottom
          >
            Your Promo Code
          </Typography>
          <Typography variant="body1" align="center" gutterBottom>
            Code: {promoCode}
          </Typography>
          <input
            type="text"
            id="promoCodeInput"
            ref={inputRef}
            value={promoCode}
            readOnly
            style={{ position: "absolute", left: "-9999px" }}
          />
          <Button
            variant="outlined"
            color="primary"
            onClick={handleCopyClick}
            className="copy-button"
            style={{
              margin: "8px auto", // Center the button horizontally
            }}
          >
            Copy Code
          </Button>
          <Typography variant="body1" align="center" gutterBottom>
            Number of Redemptions: {timesRedeemed}
          </Typography>
        </CardContent>
      </Card>
    )
  );
};

function MyDialog({ isOpen, handleClose, logs }) {
  const [autoScroll, setAutoScroll] = useState(true);
  const logsContainerRef = useRef(null);

  useLayoutEffect(() => {
    let intervalId;
    if (autoScroll) {
      intervalId = setInterval(() => {
        const { current: logsContainer } = logsContainerRef;
        if (logsContainer) {
          logsContainer.scrollTo({
            top: logsContainer.scrollHeight,
            behavior: "smooth",
          });
        }
      }, 100);
    }

    return () => {
      if (intervalId) {
        clearInterval(intervalId);
      }
    };
  }, [autoScroll]);

  return (
    <Dialog
      open={isOpen}
      onClose={handleClose}
      maxWidth="md"
      fullWidth
      PaperProps={{
        style: {
          maxHeight: "80vh",
        },
      }}
    >
      <DialogTitle>Your Server Logs</DialogTitle>
      <DialogContent dividers>
        <div
          ref={logsContainerRef}
          style={{
            fontFamily: "'Courier New', Courier, monospace",
            whiteSpace: "pre-wrap",
            wordWrap: "break-word",
            fontWeight: "bold",
            overflow: "auto",
            maxHeight: "60vh", // Adjust as needed
          }}
        >
          {Object.values(logs).map((log, index) => (
            <div key={index}>{log}</div>
          ))}
        </div>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setAutoScroll(!autoScroll)}>
          {autoScroll ? "Stop Auto Scroll" : "Start Auto Scroll"}
        </Button>
        <Button onClick={handleClose}>Close</Button>
      </DialogActions>
    </Dialog>
  );
}
const ServerIdManagement = ({ serverIds, uid }) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [deleteIndex, setDeleteIndex] = useState(null);

  const handleOpenDialog = () => {
    setOpenDialog(true);
  };

  const handleCloseDialog = () => {
    setOpenDialog(false);
    setDeleteIndex(null); // Reset delete index
  };

  const handleDeleteConfirmation = (index) => {
    setDeleteIndex(index);
  };

  const handleDeleteServerId = (serverIdToDelete) => {
    // Use toast.promise for delete operation
    toast.promise(
      axios
        .post(
          "https://us-central1-rocket-networking.cloudfunctions.net/api/deleteServerId",
          {
            uuid: uid, // Assuming uid is the user's UUID
            serverId: serverIdToDelete,
          }
        )
        .then((response) => {
          // Handle successful deletion
          setDeleteIndex(null); // Reset delete index after deletion
          return "Successfully deleted the Server Id!";
        }),
      {
        loading: "Deleting...",
        success: (message) => {
          // toast.success(message);
          return <b>{message}</b>;
        },
        error: <b>Error Deleting this Server Id!</b>,
      }
    );
  };

  const handleCreateServerId = () => {
    // Use toast.promise for create operation
    toast.promise(
      axios
        .post(
          "https://us-central1-rocket-networking.cloudfunctions.net/api/createNewServerId",
          {
            uuid: uid, // Assuming uid is the user's UUID
            serverId: uuidv4(), // Generate a new UUID
          }
        )
        .then((response) => {
          // Handle successful creation
          return "Successfully created a new Server Id!";
        }),
      {
        loading: "Creating...",
        success: (message) => {
          // toast.success(message);
          return <b>{message}</b>;
        },
        error: <b>Error Creating a new Server Id</b>,
      }
    );
    handleCloseDialog();
  };

  const handleCopyKey = (serverId) => {
    navigator.clipboard.writeText(serverId);
    toast.success("Copied to Clipboard!");
  };

  return (
    <div>
      <Button variant="contained" color="primary" onClick={handleOpenDialog}>
        Manage Server IDs
      </Button>

      <Dialog open={openDialog} onClose={handleCloseDialog}>
        <DialogTitle>Server ID Management</DialogTitle>
        <DialogContent>
          <DialogContentText>
            View and Manage your server IDs here.
          </DialogContentText>
          <List>
            {serverIds.map((id, index) => (
              <ListItem key={index}>
                <ListItemText
                  primary={id}
                  style={{
                    overflow: "hidden",
                    textOverflow: "ellipsis",
                    maxWidth: "70%",
                  }}
                />
                <ListItemSecondaryAction>
                  <IconButton
                    edge="end"
                    aria-label="copy"
                    onClick={() => handleCopyKey(id)}
                  >
                    <FileCopyIcon />
                  </IconButton>
                  <IconButton
                    edge="end"
                    aria-label="delete"
                    onClick={() => {
                      handleDeleteConfirmation(index);
                    }}
                  >
                    <DeleteIcon />
                  </IconButton>
                </ListItemSecondaryAction>
              </ListItem>
            ))}
          </List>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={handleCreateServerId}
            color="primary"
            variant="contained"
          >
            Create Server ID
          </Button>
        </DialogActions>
      </Dialog>

      {/* Delete Confirmation Dialog */}
      <Dialog
        open={deleteIndex !== null}
        onClose={handleCloseDialog}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">Confirm Deletion</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete this server ID? All games using this
            as the global.SERVERID will cease to connect to your RNet server. On
            the other hand, if you think this key is compromised, you should go
            ahead.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() => handleDeleteServerId(serverIds[deleteIndex])}
            color="primary"
            variant="contained"
          >
            Confirm Delete
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
const EditCodeDialog = ({ open, onClose, socketRef, uid }) => {
  const [loading, setLoading] = useState(false);

  return (
    <Dialog maxWidth maxHeight open={open} onClose={onClose} fullScreen>
      <DialogTitle>Edit Server Side Code {" [BETA]"} </DialogTitle>

      <DialogContent>
        <IDE uid={uid} toast={toast} socketRef={socketRef} />

        <div style={{ marginTop: "20px", textAlign: "right", gap: "10px" }}>
          <Button
            variant={
              Math.floor(Date.now() / 1000) % 2 == 0 ? "outlined" : "contained"
            }
            onClick={() => {
              window.open(
                "https://dashboard.rocketnetworking.net/visualiser",
                "_blank",
                "noopener noreferrer"
              );
            }}
            color="primary"
          >
            OPEN NO CODE VISUALISER
          </Button>
          <Button
            variant="outlined"
            color="primary"
            onClick={onClose}
            disabled={loading}
          >
            Close
          </Button>
        </div>
      </DialogContent>
    </Dialog>
  );
};

const ServerVersionDialog = ({ open, onClose, uid, serverVersion }) => {
  const [versions, setVersions] = useState([]);

  useEffect(() => {
    const fetchVersions = async () => {
      try {
        const response = await axios.get(
          "https://rocket-networking-default-rtdb.firebaseio.com/publicInfo/serverVersions.json"
        );

        setVersions(response.data || []);
      } catch (error) {
        console.error("Failed to fetch server versions:", error);
      }
    };

    fetchVersions();
  }, []);

  const changeServerVersion = async (version) => {
    console.log(`Server version wants to be to ${version}`);
    try {
      const promise = axios.post(
        "https://us-central1-rocket-networking.cloudfunctions.net/api/server-version",
        {
          branch: version.branch, // This is the request body
        },
        {
          headers: {
            Authorization: uid,
          },
          responseType: "json",
        }
      );

      toast.promise(promise, {
        loading: "Requested Server Version Change...",
        success: <b>Success! Your server will be ready in a few minutes.</b>,
        error: <b>Error!</b>,
      });

      const response = await promise;
    } catch (e) {
      console.log(e);
    }
  };

  return (
    <Dialog maxWidth="sm" fullWidth open={open} onClose={onClose}>
      <DialogTitle>Server Version Selection</DialogTitle>
      <DialogContent>
        <Typography variant="h6" align="center" gutterBottom>
          Select your server version
        </Typography>
        <Typography variant="h7" align="center" gutterBottom>
          If you just changed the version, please wait till it gets selected
          here.
        </Typography>
        <List>
          {versions.map((version, index) => (
            <ListItem key={index} divider>
              <ListItemText
                primary={
                  <div>
                    {version.branch}
                    <br />
                    {<b>{version.version}</b>}
                  </div>
                }
                secondary={version.changelog}
                primaryTypographyProps={{ component: "div" }}
              />
              <ListItemSecondaryAction>
                {serverVersion === version.version ? (
                  <IconButton edge="end" disabled>
                    <CheckCircleIcon color="success" />
                  </IconButton>
                ) : (
                  <Button
                    variant="outlined"
                    color="primary"
                    onClick={() => changeServerVersion(version)}
                  >
                    Select
                  </Button>
                )}
              </ListItemSecondaryAction>
            </ListItem>
          ))}
        </List>
      </DialogContent>
    </Dialog>
  );
};

const DiscordServerListDialog = ({ open, onClose, managedGuilds, uid }) => {
  const [selectedGuilds, setSelectedGuilds] = useState({});
  const firebaseRef = firebase
    .database()
    .ref(`users/${uid}/ownedDiscordServers`);

  useEffect(() => {
    // Fetch allowed guilds from Firebase and initialize selectedGuilds
    const fetchAllowedGuilds = async () => {
      try {
        const snapshot = await firebaseRef.once("value");
        const allowedGuilds = snapshot.val() || {};

        // Filter out guilds that no longer exist in managedGuilds
        const filteredAllowedGuilds = Object.keys(allowedGuilds)
          .filter((id) => managedGuilds.some((guild) => guild.id === id))
          .reduce((obj, key) => {
            obj[key] = allowedGuilds[key];
            return obj;
          }, {});

        // Update Firebase if there are guilds to remove
        if (
          Object.keys(filteredAllowedGuilds).length <
          Object.keys(allowedGuilds).length
        ) {
          await firebaseRef.set(filteredAllowedGuilds);
          toast.info("Updated Discord servers you own in FB.");
        }

        setSelectedGuilds(filteredAllowedGuilds);
      } catch (error) {
        console.error(error);
        toast.error("Failed to fetch data from Firebase.");
      }
    };

    fetchAllowedGuilds();
  }, []);

  const updateServersInFirebase = async (updatedGuilds) => {
    try {
      await firebaseRef.set(updatedGuilds);
      toast.success("Discord servers updated successfully.");
    } catch (error) {
      console.error(error);
      toast.error("Failed to update Discord servers in Firebase.");
    }
  };

  const handleCheckboxChange = (guildId) => {
    const updatedSelectedGuilds = {
      ...selectedGuilds,
      [guildId]: !selectedGuilds[guildId],
    };
    setSelectedGuilds(updatedSelectedGuilds);
    updateServersInFirebase(updatedSelectedGuilds);
  };

  const copyToClipboard = (text) => {
    navigator.clipboard.writeText(text).then(
      () => {
        toast.success("Discord server ID copied to clipboard");
      },
      (err) => {
        console.error("Could not copy text: ", err);
      }
    );
  };

  return (
    <Dialog onClose={onClose} open={open}>
      <DialogTitle>Your Managed Servers</DialogTitle>
      <div
        style={{
          width: "60%",
          alignSelf: "center",
        }}
      >
        <Typography variant="body1" align="center" gutterBottom>
          Check the Discord Servers that you give the RNet bot access to.{"\n"}{" "}
          Copy the Discord Server ID you want to use in your game into
          Options.gml, and make sure the RNet bot is there. Here is the bot
          link:{" "}
          <a
            href="https://discord.com/oauth2/authorize?client_id=1211409963268972664&permissions=19456&scope=bot+applications.commands"
            target="_blank"
            rel="noopener noreferrer"
          >
            Add RNet Bot
          </a>
        </Typography>
      </div>
      <List>
        {managedGuilds
          .filter((guild) => guild.owner)
          .map((guild) => (
            <ListItem key={guild.id}>
              <ListItemIcon>
                <Checkbox
                  edge="start"
                  checked={selectedGuilds[guild.id] || false}
                  onChange={() => handleCheckboxChange(guild.id)}
                  inputProps={{
                    "aria-labelledby": `checkbox-list-label-${guild.id}`,
                  }}
                />
              </ListItemIcon>
              <ListItemText
                id={`checkbox-list-label-${guild.id}`}
                primary={guild.name}
                secondary={`ID: ${guild.id}`}
              />
              <ListItemSecondaryAction>
                <IconButton
                  edge="end"
                  aria-label="copy"
                  onClick={() => copyToClipboard(guild.id)}
                >
                  <FileCopyIcon />
                </IconButton>
              </ListItemSecondaryAction>
            </ListItem>
          ))}
      </List>
    </Dialog>
  );
};

function Dashboard() {
  const [data, setData] = useState(null);
  const [plan, setPlan] = useState(0);

  const [showLogs, setShowLogs] = useState(false);
  const [logs, setLogs] = useState([
    "The Logs Start here. Do something in your game and see it update here!",
  ]);
  const socketRef = useRef(null);
  const [stateUpdateFrameRate, setStateUpdateFrameRate] = useState(0);
  const [editing, setEditing] = useState(false);

  const [promoCode, setPromoCode] = useState("");
  const [timesRedeemed, setTimesRedeemed] = useState(0);
  const [serverIds, setServerIds] = useState([]);
  const [openEditCodeDialog, setOpenEditCodeDialog] = useState(false);
  const [openDiscordIntegrationDialog, setOpenDiscordIntegrationDialog] =
    useState(false);

  const [openServerVersionDialog, setOpenServerVersionDialog] = useState(false);
  const [managedGuilds, setManagedGuilds] = useState([]);
  const [serverVersion, setServerVersion] = useState("versionless");

  const [uid, setUid] = useState(null);
  localStorage.removeItem("uuid");
  useEffect(() => {
    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (!user) {
        // No user is signed in.
        // Redirect to the login page or handle the scenario accordingly.
        window.location.href = "/";
      } else {
        setUid(user.uid);
      }
    });

    return () => unregisterAuthObserver();
  }, []);

  useEffect(() => {
    const ref = firebase.database().ref("users/" + uid);

    const initializeSocket = (serverURL) => {
      // alert("Trying to be a streamer at " + serverURL);
      if (
        !socketRef.current ||
        socketRef.current.readyState === WebSocket.CLOSED
      ) {
        socketRef.current = new WebSocket(serverURL);

        socketRef.current.addEventListener("open", () => {
          console.log("WebSocket connection opened");

          var sendThis = {
            eventName: "streamer",
            uuid: uid,
          };
          socketRef.current.send(JSON.stringify(sendThis));
        });

        // Add event listeners for messages, errors, etc.
        socketRef.current.addEventListener("message", (event) => {
          console.log("Received message:", event.data);
          var rawData = event.data.toString();
          var realData = JSON.parse(rawData);

          switch (realData.eventName) {
            case "log":
              var newLogs = logs;
              newLogs[newLogs.length] =
                "[" + getCurrentTimeWithSeconds() + "]" + realData.log;
              setLogs(newLogs);

              break;

            case "alert":
              toast(realData.message);
              break;

            case "init_metadata":
              // alert("fps is " + realData.fps);
              setStateUpdateFrameRate(realData.fps);
              setServerVersion(realData.serverVersion || "versionless");
              toast.success(
                "Server Version Detected as " + realData.serverVersion
              );
              break;
            case "set_fps":
              toast(realData.message, {
                icon: "👏",
              });
              break;
            case "set_ssc":
              toast("Server Side Code Updated!", {
                icon: "👏",
              });
              break;
          }

          // Handle the received message as needed
        });

        socketRef.current.addEventListener("close", (event) => {
          console.log("WebSocket connection closed:", event.code, event.reason);

          // Optionally, attempt to reconnect after a delay
          setTimeout(() => {
            initializeSocket(serverURL);
          }, 1000); // Adjust the delay as needed
        });

        socketRef.current.addEventListener("error", (error) => {
          console.error("WebSocket error:", error);
          toast.error(
            "Couldn't connect to your server..if this persists for more than 10mins, please contact support!"
          );
        });
      }
    };

    const handleFirebaseValue = (value) => {
      if (typeof value.plan == "number") {
        setPlan(value.plan);

        if (!data && value.serverURL.length) {
          initializeSocket("wss://" + value.serverURL);
          // initializeSocket("ws://localhost:3000");
        }
      }
      try {
        setPromoCode(value.referral.promoCode);
        setTimesRedeemed(value.referral.timesRedeemed);
      } catch (e) {}

      try {
        if (typeof value.serverIds == "object") {
          setServerIds(value.serverIds);
        }
      } catch (e) {}

      ["linode", "stm", "stripe", "emailVerified", "serverIds"].forEach(
        (key) => delete value[key]
      );

      setData(value);
    };

    const onFirebaseValue = (snapshot) => {
      const value = snapshot.val();
      handleFirebaseValue(value);
    };

    ref.on("value", onFirebaseValue);

    return () => {
      if (socketRef.current) {
        socketRef.current.close();
      }
      ref.off("value", onFirebaseValue);
    };
  }, [uid]);

  const query = useQuery();
  const code = query.get("code");

  useEffect(() => {
    if (code) {
      // Exchange the code for an access token and fetch guilds

      exchangeCodeForToken(code).then((data) => {
        if (data.managedGuilds) {
          // history.push("/dashboard"); // Using react-router-dom for redirect
          setManagedGuilds(data.managedGuilds);
          setOpenDiscordIntegrationDialog(true);
          toast.success("Loading the servers you own...");
        }
      });
    }
  }, [code]);

  // Exchange function (should actually call a backend service)
  const exchangeCodeForToken = async (code) => {
    try {
      const response = await axios.post(
        "https://us-central1-rocket-networking.cloudfunctions.net/api/discord-exchange-code",
        {
          code: code, // Send the authorization code received from Discord
        }
      );

      // Handle the response data here. For example, you might want to store the access token or use it directly.
      const { tokenData, managedGuilds } = response.data;

      // Return the response data for further processing or handling
      return { tokenData, managedGuilds };
    } catch (error) {
      console.error("Error exchanging code for token:", error);
      // Handle the error appropriately in your application context
      throw error;
    }
  };

  const handleSliderChange = (event, newValue) => {
    setStateUpdateFrameRate(newValue);
  };

  const handleSaveClick = async () => {
    // Assume you have an API endpoint to update the server frame rate
    try {
      // Make the WS call here
      // alert(socketRef.current.readyState);
      if (socketRef.current.readyState == WebSocket.OPEN) {
        socketRef.current.send(
          JSON.stringify({
            eventName: "streamer_set_fps",
            fps: stateUpdateFrameRate,
          })
        );
        toast(
          "Requested the server to change Server to Clients Update Frequency!",
          {
            icon: "👍",
            style: {
              borderRadius: "10px",
              background: "#A020F0",
              color: "#fff",
            },
          }
        );
      } else {
        toast.error(
          "The Websocket connection from here to your server is not open. Please Refresh."
        );
      }
      // If the API call is successful, update the editing state and any other necessary state
      setEditing(false);
    } catch (error) {
      console.error("Error updating server frame rate:", error);
      toast.error("Internal Error.");

      // Handle error, e.g., display an error message to the user
    }
  };

  const handleEditClick = () => {
    // Set the editing state to true when the user clicks the edit button
    setEditing(true);
  };

  const handleDownloadSnapshot = async () => {
    try {
      const apiKey = uid; // Replace with the actual API key
      const url = "https://apps.rocketnetworking.net/api/database/snapshot";

      const promise = axios.get(url, {
        headers: {
          Authorization: apiKey,
        },
        responseType: "json",
      });

      toast.promise(promise, {
        loading: "Downloading Snapshot...",
        success: <b>Snapshot downloaded!</b>,
        error: <b>Error downloading snapshot.</b>,
      });

      const response = await promise;

      const backupData = response.data;

      // Create a Blob and trigger the download
      const blob = new Blob([JSON.stringify(backupData)], {
        type: "application/json",
      });
      const link = document.createElement("a");
      link.href = URL.createObjectURL(blob);
      link.download = `${apiKey}_backup.json`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error("Error downloading backup:", error);
      // toast.error("Error downloading snapshot.");
    }
  };

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        width: "100%",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <MyDialog
        isOpen={showLogs}
        handleClose={() => {
          toast("Closed Logs", {
            icon: "🪵",
            style: {
              borderRadius: "10px",
              background: "#333",
              color: "#fff",
            },
          });
          setShowLogs(false);
        }}
        logs={logs}
      ></MyDialog>

      <DiscordServerListDialog
        open={openDiscordIntegrationDialog}
        onClose={() => {
          setOpenDiscordIntegrationDialog(false);
        }}
        managedGuilds={managedGuilds}
        uid={uid}
      />

      <EditCodeDialog
        open={openEditCodeDialog}
        onClose={() => setOpenEditCodeDialog(false)}
        socketRef={socketRef}
        uid={uid}
      />

      <ServerVersionDialog
        open={openServerVersionDialog}
        onClose={() => setOpenServerVersionDialog(false)}
        uid={uid}
        serverVersion={serverVersion}
      />

      <Toaster position="top-center" reverseOrder={false} />
      <div
        style={{
          display: "flex",
          flexDirection: "row",
          justifyContent: "center",
          width: "100%",
          height: "100%",
          gap: "5%",
          alignItems: "center",
        }}
      >
        <div
          style={{
            display: "flex",
            flexDirection: "column",
            justifyContent: "center",
            gap: "15%",
            alignItems: "center",
            width: "35%",
          }}
        >
          {" "}
          <Card
            elevation={3}
            style={{
              // width: "50%",
              margin: "1% 2%",
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "20px",
              // background: "linear-gradient(45deg, #E491F2,#FFF9A6 )", // Gradient from yellow to pink
            }}
          >
            <CardContent
              style={{
                display: "flex",
                flexDirection: "column",
                alignItems: "center",
              }}
            >
              <Typography
                style={{
                  fontWeight: "bold",
                  marginBottom: "16px",
                }}
                variant="h4"
                align="center"
                gutterBottom
              >
                Set Server Update Frequency
              </Typography>

              <Typography variant="body1" align="center" gutterBottom>
                Frequency: {stateUpdateFrameRate}
              </Typography>

              <Slider
                value={stateUpdateFrameRate}
                onChange={handleSliderChange}
                disabled={!editing}
                min={1}
                max={60}
                step={1}
                style={{
                  width: "80%",
                  margin: "16px 0",
                }}
              />

              <Button
                variant="contained"
                color="primary"
                onClick={editing ? handleSaveClick : handleEditClick}
                style={{
                  width: "80%",
                }}
              >
                {editing ? "Save" : "Edit"}
              </Button>
            </CardContent>
          </Card>
          <ReferralCard promoCode={promoCode} timesRedeemed={timesRedeemed} />
        </div>

        <MainCard
          uid={uid} // Pass the user ID
          data={data} // Pass the data for the dashboard
          plan={plan} // Pass the plan information
          setData={setData} // Pass the function to update data
          setShowLogs={setShowLogs} // Pass the function to update logs visibility
          serverIds={serverIds}
          setServerIds={setServerIds}
          setOpenEditCodeDialog={setOpenEditCodeDialog}
          setOpenServerVersionDialog={setOpenServerVersionDialog}
        />

        <div
          style={{
            display: "flex",
            flexDirection: "column",
            width: "30%",
            height: "100%", // Ensure the container div takes up full height
            marginTop: "1%",
            marginRight: "2%",
            justifyContent: "center",
            alignItems: "center",
            gap: "20px",
          }}
        >
          <Card
            elevation={3}
            style={{
              display: "flex",
              flexDirection: "column",
              justifyContent: "center",
              alignItems: "center",
              borderRadius: "20px",
            }}
          >
            <CardContent>
              <Typography
                style={{
                  fontWeight: "bold",
                }}
                variant="h5"
                align="center"
                gutterBottom
              >
                Database View
              </Typography>
              <CustomerDataOverview customerId={uid} />
              <Button
                variant="contained"
                onClick={handleDownloadSnapshot}
                color="primary"
              >
                Download Snapshot
              </Button>
            </CardContent>
          </Card>
          <iframe
            src="https://discord.com/widget?id=1192121701723557908&theme=dark"
            width="350"
            height="500"
            allowtransparency="true"
            frameborder="0"
            sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
          ></iframe>
        </div>
      </div>
    </div>
  );
}

export default Dashboard;
