import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Login from "./Login";
import Dashboard from "./Dashboard";
import IDE from "./IDE";
import GIDE from "./GIDE";
import { DiscordEmbed } from "./DiscordEmbed";

function App() {
  //Depending on the route we are on, we will render a different component
  //We will use the react-router-dom library to help us with this
  return (
    <>
      <style jsx global>{`
        body {
          background: linear-gradient(
            90deg,
            rgba(2, 0, 36, 1) 0%,
            rgba(9, 9, 121, 1) 35%,
            rgba(0, 212, 255, 1) 100%
          );
        }
      `}</style>
      <Router>
        <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/dashboard" element={<Dashboard />} />
          <Route path="/ide" element={<IDE />} />
          <Route path="/visualiser" element={<GIDE />} />
          <Route path="/discordembed" element={<DiscordEmbed />} />
        </Routes>
      </Router>
    </>
  );
}
export default App;
