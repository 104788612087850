export function DiscordEmbed() {
  return (
    <iframe
      src="https://discord.com/widget?id=1192121701723557908&theme=dark"
      width="100%"
      height="350px"
      allowtransparency="true"
      frameborder="0"
      sandbox="allow-popups allow-popups-to-escape-sandbox allow-same-origin allow-scripts"
    ></iframe>
  );
}
