import React, { useEffect } from "react";
import firebase from "firebase/compat/app";
import "firebase/compat/auth";
import { getAnalytics } from "firebase/analytics";
import * as firebaseui from "firebaseui";
import "firebaseui/dist/firebaseui.css";
import { useNavigate } from "react-router-dom";
import { Card, Typography } from "@material-ui/core";

const firebaseConfig = {
  apiKey: "AIzaSyAk_g29mja2Y01hfqotVsgwaj8eJ5DApBU",
  authDomain: "rocket-networking.firebaseapp.com",
  databaseURL: "https://rocket-networking-default-rtdb.firebaseio.com",
  projectId: "rocket-networking",
  storageBucket: "rocket-networking.appspot.com",
  messagingSenderId: "260401138500",
  appId: "1:260401138500:web:46b9fa0e14237fd297e511",
  measurementId: "G-R4GXZEHQTW",
};

const app = firebase.initializeApp(firebaseConfig);
const analytics = getAnalytics(app);
const auth = firebase.auth();
const ui = new firebaseui.auth.AuthUI(auth);

function FirebaseAuth() {
  const navigate = useNavigate();

  useEffect(() => {
    ui.start("#firebaseui-auth-container", {
      signInOptions: [
        firebase.auth.EmailAuthProvider.PROVIDER_ID,
        {
          provider: firebase.auth.GoogleAuthProvider.PROVIDER_ID,
        },
      ],
      callbacks: {
        signInSuccessWithAuthResult: function (authResult, redirectUrl) {
          // localStorage.setItem("uuid", authResult.user.uid);
          localStorage.removeItem("uuid");
          navigate("/dashboard");
          return false;
        },
      },
      signInFlow: "popup",
    });

    const unregisterAuthObserver = auth.onAuthStateChanged((user) => {
      if (user) {
        navigate("/dashboard");
      }
    });

    return () => unregisterAuthObserver();
  }, [navigate]);

  return <div id="firebaseui-auth-container"></div>;
}

function Login() {
  return (
    <div>
      <Card
        style={{
          margin: "auto",
          marginTop: "10%",
          width: "30%",
          height: "50%",
          display: "flex",

          flexDirection: "column",
          borderRadius: "20px",

          //white bg
          backgroundColor: "white",
          //shadow
          boxShadow: "0px 0px 20px rgba(0, 0, 0, 0.1)",
          //center it all
          display: "flex",
          flexDirection: "column",
          justifyContent: "center",
          alignItems: "center",
        }}
      >
        <Typography
          style={{
            //bold
            fontWeight: "bold",
            marginTop: "10%",
          }}
          variant="h4"
          align="center"
          gutterBottom
        >
          Rocket Networking
        </Typography>
        <Typography variant="h6" align="center" gutterBottom>
          Login to your account
        </Typography>
        <br />
        <FirebaseAuth />
      </Card>
    </div>
  );
}

export default Login;
