import React, { useEffect, useState } from "react";
import * as Blockly from "blockly/core";
import { blocks } from "./custom_blocks";
import { forBlock } from "./generator";
import { javascriptGenerator } from "blockly/javascript";
import * as libraryBlocks from "blockly/blocks";

import "./index.css";
import { toolbox } from "./toolbox";
import * as En from "blockly/msg/en";
Blockly.setLocale(En);

const BlocklyComponent = ({ generatedCode, setGeneratedCode }) => {
  useEffect(() => {
    Blockly.common.defineBlocks(blocks);
    Object.assign(javascriptGenerator.forBlock, forBlock);

    const workspace = Blockly.inject("blockly-container", {
      toolbox: toolbox,
      grid: {
        spacing: 20,
        length: 3,
        colour: "#ccc",
        snap: true,
      },
      zoom: {
        controls: true,
        wheel: true,
        startScale: 1.0,
        maxScale: 3,
        minScale: 0.3,
        scaleSpeed: 1.2,
      },
      trashcan: true,
    });

    // Set the generator
    const updateCode = () => {
      const code = javascriptGenerator.workspaceToCode(workspace);

      setGeneratedCode(code); // Update state with generated code
    };

    workspace.addChangeListener(() => {
      updateCode();
    });

    return () => {
      workspace.dispose();
    };
  }, []);

  return (
    <div>
      <div
        id="blockly-container"
        style={{ height: "70vh", width: "50vw" }}
      ></div>
      <button
        onClick={() => {
          const workspace = Blockly.getMainWorkspace();
          if (workspace) {
            workspace.toolbox_.flyout_.hide();
          }
        }}
      >
        Hide Toolbox
      </button>
      <button
        onClick={() => {
          const workspace = Blockly.getMainWorkspace();
          if (workspace) {
            const xmlDom = Blockly.Xml.workspaceToDom(workspace);
            const xmlText = Blockly.Xml.domToPrettyText(xmlDom);
            console.log(xmlText);
          }
        }}
      >
        Export XML
      </button>
      <button
        onClick={() => {
          console.log(generatedCode); // Access generated code here
        }}
      >
        Access Generated Code
      </button>
    </div>
  );
};

export default BlocklyComponent;
