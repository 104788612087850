/**
 * @license
 * Copyright 2023 Google LLC
 * SPDX-License-Identifier: Apache-2.0
 */

/*
This toolbox contains nearly every single built-in block that Blockly offers,
in addition to the custom block 'add_text' this sample app adds.
You probably don't need every single block, and should consider either rewriting
your toolbox from scratch, or carefully choosing whether you need each block
listed here.
*/

export const toolbox = {
  kind: "categoryToolbox",
  contents: [
    {
      kind: "category",
      name: "Logic",
      categorystyle: "logic_category",
      contents: [
        {
          kind: "block",
          type: "print",
          message0: "Print %1",
          args0: [
            {
              type: "input_value",
              name: "PRINT_VALUE",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 160,
        },
        {
          kind: "block",
          type: "controls_if",
        },
        {
          kind: "block",
          type: "logic_compare",
        },
        {
          kind: "block",
          type: "logic_operation",
        },
        {
          kind: "block",
          type: "logic_negate",
        },
        {
          kind: "block",
          type: "logic_boolean",
        },
        {
          kind: "block",
          type: "logic_null",
        },
        {
          kind: "block",
          type: "logic_ternary",
        },
      ],
    },
    {
      kind: "category",
      name: "Loops",
      categorystyle: "loop_category",
      contents: [
        {
          kind: "block",
          type: "print",
          message0: "Print %1",
          args0: [
            {
              type: "input_value",
              name: "PRINT_VALUE",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 160,
        },
        {
          kind: "block",
          type: "controls_repeat_ext",
          inputs: {
            TIMES: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 10,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "controls_whileUntil",
        },
        {
          kind: "block",
          type: "controls_for",
          inputs: {
            FROM: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            TO: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 10,
                },
              },
            },
            BY: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "controls_forEach",
        },
        {
          kind: "block",
          type: "controls_flow_statements",
        },
      ],
    },
    {
      kind: "category",
      name: "Math",
      categorystyle: "math_category",
      contents: [
        {
          kind: "block",
          type: "print",
          message0: "Print %1",
          args0: [
            {
              type: "input_value",
              name: "PRINT_VALUE",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 160,
        },
        {
          kind: "block",
          type: "math_number",
          fields: {
            NUM: 123,
          },
        },
        {
          kind: "block",
          type: "math_arithmetic",
          inputs: {
            A: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            B: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_single",
          inputs: {
            NUM: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 9,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_trig",
          inputs: {
            NUM: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 45,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_constant",
        },
        {
          kind: "block",
          type: "math_number_property",
          inputs: {
            NUMBER_TO_CHECK: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 0,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_round",
          fields: {
            OP: "ROUND",
          },
          inputs: {
            NUM: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 3.1,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_on_list",
          fields: {
            OP: "SUM",
          },
        },
        {
          kind: "block",
          type: "math_modulo",
          inputs: {
            DIVIDEND: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 64,
                },
              },
            },
            DIVISOR: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 10,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_constrain",
          inputs: {
            VALUE: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 50,
                },
              },
            },
            LOW: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            HIGH: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 100,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_random_int",
          inputs: {
            FROM: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            TO: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 100,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "math_random_float",
        },
        {
          kind: "block",
          type: "math_atan2",
          inputs: {
            X: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
            Y: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 1,
                },
              },
            },
          },
        },
      ],
    },
    {
      kind: "category",
      name: "Text",
      categorystyle: "text_category",
      contents: [
        {
          kind: "block",
          type: "print",
          message0: "Print %1",
          args0: [
            {
              type: "input_value",
              name: "PRINT_VALUE",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 160,
        },
        {
          kind: "block",
          type: "text",
        },
        {
          kind: "block",
          type: "text_multiline",
        },
        {
          kind: "block",
          type: "text_join",
        },
        {
          kind: "block",
          type: "text_append",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_length",
          inputs: {
            VALUE: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_isEmpty",
          inputs: {
            VALUE: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_indexOf",
          inputs: {
            VALUE: {
              block: {
                type: "variables_get",
              },
            },
            FIND: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_charAt",
          inputs: {
            VALUE: {
              block: {
                type: "variables_get",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_getSubstring",
          inputs: {
            STRING: {
              block: {
                type: "variables_get",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_changeCase",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_trim",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_count",
          inputs: {
            SUB: {
              shadow: {
                type: "text",
              },
            },
            TEXT: {
              shadow: {
                type: "text",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_replace",
          inputs: {
            FROM: {
              shadow: {
                type: "text",
              },
            },
            TO: {
              shadow: {
                type: "text",
              },
            },
            TEXT: {
              shadow: {
                type: "text",
              },
            },
          },
        },
        {
          kind: "block",
          type: "text_reverse",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
              },
            },
          },
        },

        {
          kind: "block",
          type: "add_text",
          inputs: {
            TEXT: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: "abc",
                },
              },
            },
            COLOR: {
              shadow: {
                type: "colour_picker",
                fields: {
                  COLOUR: "#aa00cc",
                },
              },
            },
          },
        },
      ],
    },
    {
      kind: "category",
      name: "Lists",
      categorystyle: "list_category",
      contents: [
        {
          kind: "block",
          type: "lists_create_with",
        },
        {
          kind: "block",
          type: "lists_create_with",
        },
        {
          kind: "block",
          type: "lists_repeat",
          inputs: {
            NUM: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 5,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "lists_length",
        },
        {
          kind: "block",
          type: "lists_isEmpty",
        },
        {
          kind: "block",
          type: "lists_indexOf",
          inputs: {
            VALUE: {
              block: {
                type: "variables_get",
              },
            },
          },
        },
        {
          kind: "block",
          type: "lists_getIndex",
          inputs: {
            VALUE: {
              block: {
                type: "variables_get",
              },
            },
          },
        },
        {
          kind: "block",
          type: "lists_setIndex",
          inputs: {
            LIST: {
              block: {
                type: "variables_get",
              },
            },
          },
        },
        {
          kind: "block",
          type: "lists_getSublist",
          inputs: {
            LIST: {
              block: {
                type: "variables_get",
              },
            },
          },
        },
        {
          kind: "block",
          type: "lists_split",
          inputs: {
            DELIM: {
              shadow: {
                type: "text",
                fields: {
                  TEXT: ",",
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "lists_sort",
        },
        {
          kind: "block",
          type: "lists_reverse",
        },
      ],
    },
    {
      kind: "category",
      name: "Objects",

      colour: 230,
      contents: [
        {
          kind: "block",
          type: "create_empty_object",
        },
        {
          kind: "block",
          type: "create_object",
          message0: "Create object",
          message1: "%1",
          args1: [
            {
              type: "input_statement",
              name: "PAIRS",
              check: "object_pair",
            },
          ],
          output: null,
          colour: 230,
        },
        {
          kind: "block",
          type: "object_pair",
          message0: "Key %1 Value %2",
          args0: [
            {
              type: "field_input",
              name: "KEY",
              text: "key",
            },
            {
              type: "input_value",
              name: "VALUE",
            },
          ],
          previousStatement: "object_pair",
          nextStatement: "object_pair",
          colour: 230,
        },
        {
          kind: "block",
          type: "add_to_object",
          inputs: {
            OBJECT: {
              block: {
                type: "variables_get", // Assuming you have a block for object reference
              },
            },
            KEY: {
              block: {
                type: "text", // Placeholder for the key
              },
            },
            VALUE: {
              block: {
                kind: "block",
                type: "text", // Placeholder for the value
              },
            },
          },
        },
        {
          kind: "block",
          type: "edit_object",
          inputs: {
            OBJECT: {
              block: {
                type: "variables_get", // Assuming you have a block for object reference
              },
            },
            KEY: {
              block: {
                type: "text", // Placeholder for the key
              },
            },
            VALUE: {
              block: {
                kind: "block",
                type: "text", // Placeholder for the value
              },
            },
          },
          previousStatement: null,
          nextStatement: null,
          colour: 280,
        },
        {
          kind: "block",
          type: "get_keys_from_object",
          message0: "Get keys from object %1",
          args0: [
            {
              type: "input_value",
              name: "OBJECT",
              check: "Object",
            },
          ],
          output: "Array",
          colour: 230,
          tooltip: "Get the keys from an object.",
          helpUrl: "",
        },

        {
          kind: "block",
          type: "in_object",
          inputs: {
            OBJECT: {
              block: {
                kind: "block",
                type: "variables_get", // Placeholder for object reference
              },
            },
            KEY: {
              block: {
                kind: "block",
                type: "text", // Placeholder for the key
              },
            },
          },
        },

        {
          kind: "block",
          type: "delete_key",
          inputs: {
            OBJECT: {
              block: {
                kind: "block",
                type: "variables_get", // Placeholder for object reference
              },
            },
            KEY: {
              block: {
                kind: "block",
                type: "text", // Placeholder for the key to be deleted
              },
            },
          },
        },

        {
          kind: "block",
          type: "for_key_in_object",
          message0: "For key %1 in Dict %2",
          args0: [
            {
              type: "field_variable",
              name: "KEYYYY",
              variable: "item",
            },
            {
              type: "input_value",
              name: "OBJECT",
            },
          ],
          message1: "%1",
          args1: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 230,
        },
      ],
    },

    {
      kind: "category",
      name: "Color",
      categorystyle: "colour_category",
      contents: [
        {
          kind: "block",
          type: "colour_picker",
        },
        {
          kind: "block",
          type: "colour_random",
        },
        {
          kind: "block",
          type: "colour_rgb",
          inputs: {
            RED: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 100,
                },
              },
            },
            GREEN: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 50,
                },
              },
            },
            BLUE: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 0,
                },
              },
            },
          },
        },
        {
          kind: "block",
          type: "colour_blend",
          inputs: {
            COLOUR1: {
              shadow: {
                type: "colour_picker",
                fields: {
                  COLOUR: "#ff0000",
                },
              },
            },
            COLOUR2: {
              shadow: {
                type: "colour_picker",
                fields: {
                  COLOUR: "#3333ff",
                },
              },
            },
            RATIO: {
              shadow: {
                type: "math_number",
                fields: {
                  NUM: 0.5,
                },
              },
            },
          },
        },
      ],
    },

    {
      kind: "sep",
    },
    {
      kind: "category",
      name: "RNet Creation Events",
      colour: 110,
      contents: [
        {
          kind: "block",
          type: "print",
          message0: "Print %1",
          args0: [
            {
              type: "input_value",
              name: "PRINT_VALUE",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 160,
        },
        {
          kind: "block",
          type: "get_game_constant",
          message0: "Game Constant",
          output: null,
          colour: 120,
          tooltip: "Get the 'game' constant.",
          helpUrl: "",
        },
        {
          kind: "block",
          type: "get_room_constant",
          message0: "Room Constant",
          output: null,
          colour: 120,
          tooltip: "Get the 'room' constant.",
          helpUrl: "",
        },
        {
          kind: "block",
          type: "get_client_constant",
          message0: "Client Constant",
          output: null,
          colour: 120,
          tooltip: "Get the 'client' constant.",
          helpUrl: "",
        },

        {
          kind: "block",
          type: "get_entity_constant",
          message0: "Entity",
          output: null,
          colour: 120,
          tooltip: "Get the 'entity' constant.",
          helpUrl: "",
        },

        {
          kind: "block",
          type: "get_persistent_object_constant",
          message0: "Persistent Object",
          output: null,
          colour: 120,
          tooltip: "Get the 'persistent object' constant.",
          helpUrl: "",
        },

        {
          kind: "block",
          type: "step_block",
          message0: "Step %1",
          args0: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          colour: 120,
          tooltip: "Represents a step in the server-side script.",
          helpUrl: "",
        },

        {
          kind: "block",
          type: "game_server_created_block",
          message0: "Game Server Created %1",
          args0: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          colour: 120,
          tooltip:
            "Represents the creation of a game server in the server-side script.",
          helpUrl: "",
        },
        {
          kind: "block",
          type: "room_created_block",
          message0: "Room Created %1",
          args0: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          colour: 120,
          tooltip:
            "Represents the creation of a room in the server-side script.",
          helpUrl: "",
        },
        {
          kind: "block",
          type: "client_created_block",
          message0: "Client Created %1",
          args0: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          colour: 120,
          tooltip:
            "Represents the creation of a client in the server-side script.",
          helpUrl: "",
        },
        {
          kind: "block",
          type: "entity_created_block",
          message0: "Entity Created %1",
          args0: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          colour: 120,
          tooltip:
            "Represents the creation of an entity in the server-side script.",
          helpUrl: "",
        },
        {
          kind: "block",
          type: "persistent_object_created_block",
          message0: "Persistent Object Created %1",
          args0: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          colour: 120,
          tooltip:
            "Represents the creation of a persistent object in the server-side script.",
          helpUrl: "",
        },
        // Add other constant-related blocks here...
      ],
    },
    {
      kind: "category",
      name: "RNet Step Event",
      colour: 50,
      contents: [
        {
          kind: "block",
          type: "print",
          message0: "Print %1",
          args0: [
            {
              type: "input_value",
              name: "PRINT_VALUE",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 160,
        },
        {
          kind: "block",
          type: "get_game_constant",
          message0: "Game Constant",
          output: null,
          colour: 120,
          tooltip: "Get the 'game' constant.",
          helpUrl: "",
        },

        {
          kind: "block",
          type: "step_block",
          message0: "Step %1",
          args0: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          colour: 120,
          tooltip: "Represents a step in the server-side script.",
          helpUrl: "",
        },
        {
          kind: "block",
          type: "for_rooms_in_game",
          message0: "For roomId %1 in Game %2",
          args0: [
            {
              type: "field_variable",
              name: "ROOM_ID",
              variable: "roomId",
            },
            {
              type: "input_value",
              name: "OBJECT",
            },
          ],
          message1: "%1",
          args1: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 230,
        },
        {
          kind: "block",
          type: "for_clients_in_room",
          message0: "For clientId %1 in Room %2",
          args0: [
            {
              type: "field_variable",
              name: "CLIENT_ID",
              variable: "clientId",
            },
            {
              type: "input_value",
              name: "OBJECT",
            },
          ],
          message1: "%1",
          args1: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 230,
        },

        {
          kind: "block",
          type: "for_entities_in_client",
          message0: "For entityId %1 in Client %2",
          args0: [
            {
              type: "field_variable",
              name: "ENTITY_ID",
              variable: "entityId",
            },
            {
              type: "input_value",
              name: "OBJECT",
            },
          ],
          message1: "%1",
          args1: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 230,
        },
        {
          kind: "block",
          type: "for_persistent_objects_in_room",
          message0: "For persistentObjectId %1 in Room %2",
          args0: [
            {
              type: "field_variable",
              name: "PERSISTENT_OBJECT_ID",
              variable: "persistentObjectId",
            },
            {
              type: "input_value",
              name: "OBJECT",
            },
          ],
          message1: "%1",
          args1: [
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 230,
        },
        {
          kind: "block",
          type: "get_room",
          message0: "Get room with ID %1",
          args0: [
            {
              type: "input_value",
              name: "ROOM_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
          ],
          output: null, // You can specify a particular type if needed
          colour: 230,
        },
        {
          kind: "block",
          type: "get_client",
          message0: "Get client with ID %1 from room ID %2",
          args0: [
            {
              type: "input_value",
              name: "CLIENT_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
            {
              type: "input_value",
              name: "ROOM_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
          ],
          output: null, // You can specify a particular type if needed
          colour: 230,
        },

        {
          kind: "block",
          type: "get_entity",
          message0: "Get entity with ID %1 from client ID %2 in room ID %3",
          args0: [
            {
              type: "input_value",
              name: "ENTITY_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
            {
              type: "input_value",
              name: "CLIENT_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
            {
              type: "input_value",
              name: "ROOM_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
          ],
          output: null, // You can specify a particular type if needed
          colour: 230,
        },
        {
          kind: "block",
          type: "get_persistent_object",
          message0: "Get persistent object with ID %1 from room ID %2",
          args0: [
            {
              type: "input_value",
              name: "PERSISTENT_OBJECT_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
            {
              type: "input_value",
              name: "ROOM_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
          ],
          output: null, // You can specify a particular type if needed
          colour: 230,
        },
      ],
    },
    {
      kind: "category",
      name: "Client-Server Events",
      colour: 160,
      contents: [
        {
          kind: "block",
          type: "client_sent_event_block",
          message0: "When Client Sent event %1 %2",
          args0: [
            {
              type: "input_dummy",
              name: "TOP_TEXT",
              align: "CENTRE",
            },
            {
              type: "input_statement",
              name: "STATEMENTS",
            },
          ],
          colour: 160,
          tooltip: "",
          helpUrl: "",
          inputsInline: false, // Set inputsInline to false to make it vertical
        },
        // Add other toolbox definitions here...
        {
          kind: "block",
          type: "get_sender_client_id_constant",
          message0: "Sender Client ID",
          output: null,
          colour: 160,
          tooltip: "Get the sender client's client ID.",
          helpUrl: "",
        },
        {
          kind: "block",
          type: "get_event_name_constant",
          message0: "Event Name Constant",
          output: null,
          colour: 160,
          tooltip: "Get the event name.",
          helpUrl: "",
        },
        {
          kind: "block",
          type: "get_message_struct_constant",
          message0: "Message Struct Constant",
          output: null,
          colour: 160,
          tooltip: "Get the message as an object .",
          helpUrl: "",
        },
        {
          kind: "block",
          type: "setcfs",
          message0: "Send event to Client %1 from Server",
          args0: [
            {
              type: "input_value",
              name: "CLIENT",
              // check: "Variable",
            },
          ],
          message1: "EventName : %1 and MessageStruct Dict : %2",
          args1: [
            {
              type: "input_value",
              name: "EVENT_NAME",
            },
            {
              type: "input_value",
              name: "MESSAGE_STRUCT",
            },
          ],
          previousStatement: null,
          nextStatement: null,

          colour: 160,
        },
      ],
    },
    {
      kind: "category",
      name: "Client SP",
      colour: 280,
      contents: [
        {
          kind: "block",
          type: "get_sp_method",
          message0: "Get SP of Client %1",
          args0: [
            {
              type: "input_value",
              name: "VARIABLE",
              // check: "Variable", // Adjust the check type as per your requirement
            },
          ],
          output: null, // The output type can be specified if needed
          colour: 280,
        },
        {
          kind: "block",
          type: "get_sps_method",
          message0: "Get Server's SP of Client %1",
          args0: [
            {
              type: "input_value",
              name: "VARIABLE",
              // check: "Variable", // Adjust the check type as per your requirement
            },
          ],
          output: null, // The output type can be specified if needed
          colour: 280,
        },
        {
          kind: "block",
          type: "set_sp",
          message0: "Set SP of Client %1 to Dict %2",
          args0: [
            {
              type: "input_value",
              name: "CLIENT",
              // check: "Variable",
            },
            {
              type: "input_value",
              name: "NEW_SP",
              // check: "Variable",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 280,
        },
        {
          kind: "block",
          type: "set_sps",
          message0: "Set Server's SP of Client %1 to Dict %2",
          args0: [
            {
              type: "input_value",
              name: "CLIENT",
              // check: "Variable",
            },
            {
              type: "input_value",
              name: "NEW_SPS",
              // check: "Variable",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 280,
        },
        {
          kind: "block",
          type: "get_client",
          message0: "Get client with ID %1 from room ID %2",
          args0: [
            {
              type: "input_value",
              name: "CLIENT_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
            {
              type: "input_value",
              name: "ROOM_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
          ],
          output: null, // You can specify a particular type if needed
          colour: 230,
        },
      ],
    },
    {
      kind: "category",
      name: "Entity and EP",
      colour: 50,
      contents: [
        {
          kind: "block",
          type: "get_ep_method",
          message0: "Get EP of Entity %1",
          args0: [
            {
              type: "input_value",
              name: "VARIABLE",
              // check: "Variable", // Adjust the check type as per your requirement
            },
          ],
          output: null, // The output type can be specified if needed
          colour: 50,
        },
        {
          kind: "block",
          type: "get_eps_method",
          message0: "Get Server's EP of Entity  %1",
          args0: [
            {
              type: "input_value",
              name: "VARIABLE",
              // check: "Variable", // Adjust the check type as per your requirement
            },
          ],
          output: null, // The output type can be specified if needed
          colour: 50,
        },
        {
          kind: "block",
          type: "set_ep",
          message0: "Set EP of Entity %1 to Dict %2",
          args0: [
            {
              type: "input_value",
              name: "ENTITY",
              // check: "Variable",
            },
            {
              type: "input_value",
              name: "NEW_EP",
              // check: "Variable",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 50,
        },
        {
          kind: "block",
          type: "set_eps",
          message0: "Set Server's EP of Entity %1 to Dict %2",
          args0: [
            {
              type: "input_value",
              name: "CLIENT",
              // check: "Variable",
            },
            {
              type: "input_value",
              name: "NEW_EPS",
              // check: "Variable",
            },
          ],
          previousStatement: null,
          nextStatement: null,
          colour: 50,
        },
        {
          kind: "block",
          type: "get_entity",
          message0: "Get entity with ID %1 from client ID %2 in room ID %3",
          args0: [
            {
              type: "input_value",
              name: "ENTITY_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
            {
              type: "input_value",
              name: "CLIENT_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
            {
              type: "input_value",
              name: "ROOM_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
          ],
          output: null, // You can specify a particular type if needed
          colour: 230,
        },
      ],
    },
    {
      kind: "category",
      name: "Persistent Obj",
      colour: 10,
      contents: [
        {
          kind: "block",
          type: "create_po",
          message0:
            "Create Persistent Object from properties dict %1 in Room %2",
          args0: [
            {
              type: "input_value",
              name: "POP",
              // check: "Variable", // Adjust the check type as per your requirement
            },
            {
              type: "input_value",
              name: "ROOM",
              // check: "Variable", // Adjust the check type as per your requirement
            },
          ],
          output: null, // The output type can be specified if needed
          colour: 10,
        },
        {
          kind: "block",
          type: "get_pop",
          message0: "Get Properties dict of Persistent Object %1",
          args0: [
            {
              type: "input_value",
              name: "VARIABLE",
              // check: "Variable", // Adjust the check type as per your requirement
            },
          ],
          output: null, // The output type can be specified if needed
          colour: 10,
          previousStatement: null,
          nextStatement: null,
        },
        {
          kind: "block",
          type: "set_pop",
          message0: "Set Properties dict of Persistent Object %1 to %2",
          args0: [
            {
              type: "input_value",
              name: "VARIABLE",
              // check: "Variable", // Adjust the check type as per your requirement
            },
            {
              type: "input_value",
              name: "NEW_POP",
              // check: "Variable", // Adjust the check type as per your requirement
            },
          ],
          output: null, // The output type can be specified if needed
          colour: 10,
          previousStatement: null,
          nextStatement: null,
        },
        {
          kind: "block",
          type: "destroy_po",
          message0: "Destroy the  Persistent Object %1 ",
          args0: [
            {
              type: "input_value",
              name: "VARIABLE",
              // check: "Variable", // Adjust the check type as per your requirement
            },
          ],
          output: null, // The output type can be specified if needed
          colour: 10,
        },
        {
          kind: "block",
          type: "get_persistent_object",
          message0: "Get persistent object with ID %1 from room ID %2",
          args0: [
            {
              type: "input_value",
              name: "PERSISTENT_OBJECT_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
            {
              type: "input_value",
              name: "ROOM_ID",
              // check: "String", // You can adjust the check type as per your requirement
            },
          ],
          output: null, // You can specify a particular type if needed
          colour: 230,
        },
      ],
    },
    {
      kind: "category",
      name: "Database",
      colour: 120,
      contents: [
        {
          kind: "block",
          type: "get_data_from_db",
          message0: "Get data from collection %1 document %2 ",
          args0: [
            {
              type: "input_value",
              name: "COLLECTION_NAME",
              // check: "String",
            },
            {
              type: "input_value",
              name: "DOCUMENT_NAME",
              // check: "String",
            },
          ],
          message1: "With %1 Do %2",
          args1: [
            {
              type: "field_variable",
              name: "RESPONSE",
              variable: "response",
            },
            {
              type: "input_statement",
              name: "DO",
            },
          ],
          colour: 120,
        },
        {
          kind: "block",
          type: "set_data_in_db",
          message0: "Set data in collection %1 in document %2 ",
          args0: [
            {
              type: "input_value",
              name: "COLLECTION_NAME",
              // check: "String",
            },
            {
              type: "input_value",
              name: "DOCUMENT_NAME",
              // check: "String",
            },
          ],
          message1: "to Dict %1",
          args1: [
            {
              type: "input_value",
              name: "DOCUMENT",
            },
          ],
          colour: 120,
        },

        {
          kind: "block",
          type: "delete_data_in_db",
          message0: "Delete document %2 in collection %1 ",
          args0: [
            {
              type: "input_value",
              name: "COLLECTION_NAME",
              // check: "String",
            },
            {
              type: "input_value",
              name: "DOCUMENT_NAME",
              // check: "String",
            },
          ],

          colour: 120,
        },

        {
          kind: "block",
          type: "patch_data_in_db",
          message0: "Patch data in collection %1 in document %2 ",
          args0: [
            {
              type: "input_value",
              name: "COLLECTION_NAME",
              // check: "String",
            },
            {
              type: "input_value",
              name: "DOCUMENT_NAME",
              // check: "String",
            },
          ],
          message1: "with Dict %1",
          args1: [
            {
              type: "input_value",
              name: "DOCUMENT",
            },
          ],
          colour: 120,
        },
      ],
    },
    {
      kind: "sep",
    },
    {
      kind: "category",
      name: "Variables",
      categorystyle: "variable_category",
      custom: "VARIABLE",
    },

    {
      kind: "category",
      name: "Functions",
      categorystyle: "procedure_category",
      custom: "PROCEDURE",
    },
  ],
};
