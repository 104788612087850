import * as Blockly from "blockly/core";

// Create a custom block called 'add_text' that adds
// text to the output div on the sample app.
// This is just an example and you should replace this with your
// own custom blocks.
const addText = {
  type: "add_text",
  message0: "Add text %1 with color %2",
  args0: [
    {
      type: "input_value",
      name: "TEXT",
      check: "String",
    },
    {
      type: "input_value",
      name: "COLOR",
      check: "Colour",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
  tooltip: "",
  helpUrl: "",
};
// Create a custom block called 'create_empty_object' that creates an empty object.
const createEmptyObject = {
  type: "create_empty_object",
  message0: "Create empty object",
  output: "Object",
  colour: 230,
  tooltip: "",
  helpUrl: "",
};
const c1 = {
  kind: "block",
  type: "create_object",
  message0: "Create object",
  message1: "%1",
  args1: [
    {
      type: "input_statement",
      name: "PAIRS",
      check: "object_pair",
    },
  ],
  output: null,
  colour: 230,
};
const c2 = {
  kind: "block",
  type: "object_pair",
  message0: "Key %1 Value %2",
  args0: [
    {
      type: "field_input",
      name: "KEY",
      text: "key",
    },
    {
      type: "input_value",
      name: "VALUE",
    },
  ],
  previousStatement: "object_pair",
  nextStatement: "object_pair",
  colour: 230,
};
// Create a custom block called 'add_to_object' that adds a key-value pair to an object.
const addToObject = {
  type: "add_to_object",
  message0: "Add to object %1 key %2 value %3",
  args0: [
    {
      type: "input_value",
      name: "OBJECT",
      check: "Object",
    },
    {
      type: "input_value",
      name: "KEY",
      check: "String",
    },
    {
      type: "input_value",
      name: "VALUE",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 230,
  tooltip: "",
  helpUrl: "",
};
const setObjectVar = {
  kind: "block",
  type: "variables_set",
  message0: "set %1 to %2",
  args0: [
    {
      type: "field_variable",
      name: "VAR",
      variable: "item",
    },
    {
      type: "input_value",
      name: "VALUE",
    },
  ],
  inputsInline: true,
  previousStatement: null,
  nextStatement: null,
  colour: 230,
  tooltip: "Set the value of a variable.",
  helpUrl: "",
  extensions: ["contextMenu_setParent"],
};
// Create a custom block called 'in_object' that retrieves the value of a key in an object.
const inObject = {
  type: "in_object",
  message0: "In object %1 get value of key %2",
  args0: [
    {
      type: "input_value",
      name: "OBJECT",
      check: "Object",
    },
    {
      type: "input_value",
      name: "KEY",
      check: "String",
    },
  ],
  output: null,
  colour: 230,
  tooltip: "",
  helpUrl: "",
};

const getKeys = {
  kind: "block",
  type: "get_keys_from_object",
  message0: "Get keys from object %1",
  args0: [
    {
      type: "input_value",
      name: "OBJECT",
      check: "Object",
    },
  ],
  output: "Array",
  colour: 230,
  tooltip: "Get the keys from an object.",
  helpUrl: "",
};

// const getKeyValue = {
//   kind: "block",
//   type: "get_value_of_key",
//   inputs: {
//     OBJECT: {
//       block: {
//         kind: "block",
//         type: "variables_get", // Placeholder for object reference
//       },
//     },
//     KEY: {
//       block: {
//         kind: "block",
//         type: "text", // Placeholder for the key
//       },
//     },
//   },
// };
// Create a custom block called 'delete_key' that deletes a key from an object.
const deleteKey = {
  type: "delete_key",
  message0: "In object %1 delete key %2",
  args0: [
    {
      type: "input_value",
      name: "OBJECT",
      check: "Object",
    },
    {
      type: "input_value",
      name: "KEY",
      check: "String",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 230,
  tooltip: "",
  helpUrl: "",
};
const forKeyInObject = {
  kind: "block",
  type: "for_key_in_object",
  message0: "For key %1 in Dict %2",
  args0: [
    {
      type: "field_variable",
      name: "KEYYYY",
      variable: "key",
    },
    {
      type: "input_value",
      name: "OBJECT",
    },
  ],
  message1: "%1",
  args1: [
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 230,
};

const forRoomsInGame = {
  kind: "block",
  type: "for_rooms_in_game",
  message0: "For roomId %1 in Game %2",
  args0: [
    {
      type: "field_variable",
      name: "ROOM_ID",
      variable: "roomId",
    },
    {
      type: "input_value",
      name: "OBJECT",
    },
  ],
  message1: "%1",
  args1: [
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 230,
};

const forClientsInRoom = {
  kind: "block",
  type: "for_clients_in_room",
  message0: "For clientId %1 in Room %2",
  args0: [
    {
      type: "field_variable",
      name: "CLIENT_ID",
      variable: "clientId",
    },
    {
      type: "input_value",
      name: "OBJECT",
    },
  ],
  message1: "%1",
  args1: [
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 230,
};

const forPOinRoom = {
  kind: "block",
  type: "for_persistent_objects_in_room",
  message0: "For persistentObjectId %1 in Room %2",
  args0: [
    {
      type: "field_variable",
      name: "PERSISTENT_OBJECT_ID",
      variable: "persistentObjectId",
    },
    {
      type: "input_value",
      name: "OBJECT",
    },
  ],
  message1: "%1",
  args1: [
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 230,
};

const forEntitiesInClient = {
  kind: "block",
  type: "for_entities_in_client",
  message0: "For entityId %1 in Client %2",
  args0: [
    {
      type: "field_variable",
      name: "ENTITY_ID",
      variable: "entityId",
    },
    {
      type: "input_value",
      name: "OBJECT",
    },
  ],
  message1: "%1",
  args1: [
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 230,
};

// Create a custom block called 'get_game_constant' that retrieves the 'game' constant.
const getGameConstant = {
  type: "get_game_constant",
  message0: "Game",
  output: null,
  colour: 120,
  tooltip: "Get the 'game' constant.",
  helpUrl: "",
};

// Create a custom block called 'get_room_constant' that retrieves the 'room' constant.
const getRoomConstant = {
  type: "get_room_constant",
  message0: "Room",
  output: null,
  colour: 120,
  tooltip: "Get the 'room' constant.",
  helpUrl: "",
};

// Create a custom block called 'get_client_constant' that retrieves the 'client' constant.
const getClientConstant = {
  type: "get_client_constant",
  message0: "Client",
  output: null,
  colour: 120,
  tooltip: "Get the 'client' constant.",
  helpUrl: "",
};
const getEntityConstent = {
  kind: "block",
  type: "get_entity_constant",
  message0: "Entity",
  output: null,
  colour: 120,
  tooltip: "Get the 'entity' constant.",
  helpUrl: "",
};

const getPersistentbjectConstant = {
  kind: "block",
  type: "get_persistent_object_constant",
  message0: "Persistent Object",
  output: null,
  colour: 120,
  tooltip: "Get the 'persistent object' constant.",
  helpUrl: "",
};

const getEventNameConstant = {
  kind: "block",
  type: "get_event_name_constant",
  message0: "Event Name",
  output: null,
  colour: 160,
  tooltip: "Get the event name.",
  helpUrl: "",
};
const getMessageStructConstant = {
  kind: "block",
  type: "get_message_struct_constant",
  message0: "Message Struct",
  output: null,
  colour: 160,
  tooltip: "Get the message as an object .",
  helpUrl: "",
};

const setcfs = {
  kind: "block",
  type: "setcfs",
  message0: "Send event to Client %1 from Server",
  args0: [
    {
      type: "input_value",
      name: "CLIENT",
      // check: "Variable",
    },
  ],
  message1: "EventName : %1 and MessageStruct Dict : %2",
  args1: [
    {
      type: "input_value",
      name: "EVENT_NAME",
    },
    {
      type: "input_value",
      name: "MESSAGE_STRUCT",
    },
  ],
  previousStatement: null,
  nextStatement: null,

  colour: 160,
};
const getSenderClientIdConstant = {
  kind: "block",
  type: "get_sender_client_id_constant",
  message0: "Sender Client ID",
  output: null,
  colour: 160,
  tooltip: "Get the sender client's client ID.",
  helpUrl: "",
};
// Create a custom block for 'step' statement.
const stepBlock = {
  type: "step_block",
  message0: "Step %1 %2",
  args0: [
    {
      type: "input_dummy",
      name: "TOP_TEXT",
      align: "CENTRE",
    },
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  colour: 120,
  tooltip: "Represents a step in the server-side script.",
  helpUrl: "",
};

// Create a custom block for 'client_sent_event' statement.
const clientSentEventBlock = {
  kind: "block",
  type: "client_sent_event_block",
  message0: "When Client Sent event %1 %2",
  args0: [
    {
      type: "input_dummy",
      name: "TOP_TEXT",
      align: "CENTRE",
    },
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  colour: 160,
  tooltip: "",
  helpUrl: "",
  inputsInline: false, // Set inputsInline to false to make it vertical
};

// Create a custom block for 'game_server_created' statement.
const gameServerCreatedBlock = {
  type: "game_server_created_block",
  message0: "Game Server Created %1 %2",
  args0: [
    {
      type: "input_dummy",
      name: "TOP_TEXT",
      align: "CENTRE",
    },
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  colour: 120,
  tooltip:
    "Represents the creation of a game server in the server-side script.",
  helpUrl: "",
};

// Create a custom block for 'room_created' statement.
const roomCreatedBlock = {
  type: "room_created_block",
  message0: "Room Created %1 %2",
  args0: [
    {
      type: "input_dummy",
      name: "TOP_TEXT",
      align: "CENTRE",
    },
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  colour: 120,
  tooltip: "Represents the creation of a room in the server-side script.",
  helpUrl: "",
};

// Create a custom block for 'client_created' statement.
const clientCreatedBlock = {
  type: "client_created_block",
  message0: "Client Created %1 %2",
  args0: [
    {
      type: "input_dummy",
      name: "TOP_TEXT",
      align: "CENTRE",
    },
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  colour: 120,
  tooltip: "Represents the creation of a client in the server-side script.",
  helpUrl: "",
};

const entityCreatedBlock = {
  kind: "block",
  type: "entity_created_block",
  message0: "Entity Created %1 %2",
  args0: [
    {
      type: "input_dummy",
      name: "TOP_TEXT",
      align: "CENTRE",
    },
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  colour: 120,
  tooltip: "Represents the creation of an entity in the server-side script.",
  helpUrl: "",
};

const persistentObjectCreated = {
  kind: "block",
  type: "persistent_object_created_block",
  message0: "Persistent Object Created %1 %2",
  args0: [
    {
      type: "input_dummy",
      name: "TOP_TEXT",
      align: "CENTRE",
    },
    {
      type: "input_statement",
      name: "STATEMENTS",
    },
  ],
  colour: 120,
  tooltip:
    "Represents the creation of a persistent object in the server-side script.",
  helpUrl: "",
};

const getRoom = {
  kind: "block",
  type: "get_room",
  message0: "Get room with ID %1",
  args0: [
    {
      type: "input_value",
      name: "ROOM_ID",
      // check: "String", // You can adjust the check type as per your requirement
    },
  ],
  output: null, // You can specify a particular type if needed
  colour: 230,
};

const getClient = {
  kind: "block",
  type: "get_client",
  message0: "Get client with ID %1 from room ID %2",
  args0: [
    {
      type: "input_value",
      name: "CLIENT_ID",
      // check: "String", // You can adjust the check type as per your requirement
    },
    {
      type: "input_value",
      name: "ROOM_ID",
      // check: "String", // You can adjust the check type as per your requirement
    },
  ],
  output: null, // You can specify a particular type if needed
  colour: 230,
};

const getEntity = {
  kind: "block",
  type: "get_entity",
  message0: "Get entity with ID %1 from client ID %2 in room ID %3",
  args0: [
    {
      type: "input_value",
      name: "ENTITY_ID",
      // check: "String", // You can adjust the check type as per your requirement
    },
    {
      type: "input_value",
      name: "CLIENT_ID",
      // check: "String", // You can adjust the check type as per your requirement
    },
    {
      type: "input_value",
      name: "ROOM_ID",
      // check: "String", // You can adjust the check type as per your requirement
    },
  ],
  output: null, // You can specify a particular type if needed
  colour: 230,
};
const getPO = {
  kind: "block",
  type: "get_persistent_object",
  message0: "Get persistent object with ID %1 from room ID %2",
  args0: [
    {
      type: "input_value",
      name: "PERSISTENT_OBJECT_ID",
      // check: "String", // You can adjust the check type as per your requirement
    },
    {
      type: "input_value",
      name: "ROOM_ID",
      // check: "String", // You can adjust the check type as per your requirement
    },
  ],
  output: null, // You can specify a particular type if needed
  colour: 230,
};

const getSP = {
  kind: "block",
  type: "get_sp_method",
  message0: "Get SP of Client %1",
  args0: [
    {
      type: "input_value",
      name: "VARIABLE",
      // check: "Variable", // Adjust the check type as per your requirement
    },
  ],
  output: null, // The output type can be specified if needed
  colour: 280,
};

const getSPS = {
  kind: "block",
  type: "get_sps_method",
  message0: "Get Server's SP of Client %1",
  args0: [
    {
      type: "input_value",
      name: "VARIABLE",
      // check: "Variable", // Adjust the check type as per your requirement
    },
  ],
  output: null, // The output type can be specified if needed
  colour: 280,
};

const setSP = {
  kind: "block",
  type: "set_sp",
  message0: "Set SP of Client %1 to Dict %2",
  args0: [
    {
      type: "input_value",
      name: "CLIENT",
      // check: "Variable",
    },
    {
      type: "input_value",
      name: "NEW_SP",
      // check: "Variable",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 280,
};

const setSPS = {
  kind: "block",
  type: "set_sps",
  message0: "Set Server's SP of Client %1 to Dict %2",
  args0: [
    {
      type: "input_value",
      name: "CLIENT",
      // check: "Variable",
    },
    {
      type: "input_value",
      name: "NEW_SPS",
      // check: "Variable",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 280,
};

const editObject = {
  type: "edit_object",
  message0: "Edit to object %1 key %2 value %3",
  args0: [
    {
      type: "input_value",
      name: "OBJECT",
      check: "Object",
    },
    {
      type: "input_value",
      name: "KEY",
      check: "String",
    },
    {
      type: "input_value",
      name: "VALUE",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 230,
  tooltip: "",
  helpUrl: "",
};
//EP stuff

const getEP = {
  kind: "block",
  type: "get_ep_method",
  message0: "Get EP of Entity %1",
  args0: [
    {
      type: "input_value",
      name: "VARIABLE",
      // check: "Variable", // Adjust the check type as per your requirement
    },
  ],
  output: null, // The output type can be specified if needed
  colour: 50,
};

const getEPS = {
  kind: "block",
  type: "get_eps_method",
  message0: "Get Server's EP of Entity  %1",
  args0: [
    {
      type: "input_value",
      name: "VARIABLE",
      // check: "Variable", // Adjust the check type as per your requirement
    },
  ],
  output: null, // The output type can be specified if needed
  colour: 50,
};
const setEP = {
  kind: "block",
  type: "set_ep",
  message0: "Set EP of Entity %1 to Dict %2",
  args0: [
    {
      type: "input_value",
      name: "ENTITY",
      // check: "Variable",
    },
    {
      type: "input_value",
      name: "NEW_EP",
      // check: "Variable",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 50,
};
const setEPS = {
  kind: "block",
  type: "set_eps",
  message0: "Set Server's EP of Entity %1 to Dict %2",
  args0: [
    {
      type: "input_value",
      name: "ENTITY",
      // check: "Variable",
    },
    {
      type: "input_value",
      name: "NEW_EPS",
      // check: "Variable",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 50,
};

//pop

const createPO = {
  kind: "block",
  type: "create_po",
  message0: "Create Persistent Object from properties dict %1 in Room %2",
  args0: [
    {
      type: "input_value",
      name: "POP",
      // check: "Variable", // Adjust the check type as per your requirement
    },
    {
      type: "input_value",
      name: "ROOM",
      // check: "Variable", // Adjust the check type as per your requirement
    },
  ],
  output: null, // The output type can be specified if needed
  colour: 10,
};
const getPOP = {
  kind: "block",
  type: "get_pop",
  message0: "Get Properties dict of Persistent Object %1",
  args0: [
    {
      type: "input_value",
      name: "VARIABLE",
      // check: "Variable", // Adjust the check type as per your requirement
    },
  ],
  output: null, // The output type can be specified if needed
  colour: 10,
};

const setPOP = {
  kind: "block",
  type: "set_pop",
  message0: "Set Properties dict of Persistent Object %1 to %2",
  args0: [
    {
      type: "input_value",
      name: "VARIABLE",
      // check: "Variable", // Adjust the check type as per your requirement
    },
    {
      type: "input_value",
      name: "NEW_POP",
      // check: "Variable", // Adjust the check type as per your requirement
    },
  ],
  output: null, // The output type can be specified if needed
  colour: 10,
};

const destroyPO = {
  kind: "block",
  type: "destroy_po",
  message0: "Destroy the  Persistent Object %1 ",
  args0: [
    {
      type: "input_value",
      name: "VARIABLE",
      // check: "Variable", // Adjust the check type as per your requirement
    },
  ],
  output: null, // The output type can be specified if needed
  colour: 10,
};

//database
const getDataFromDB = {
  kind: "block",
  type: "get_data_from_db",
  message0: "Get data from collection %1 document %2 ",
  args0: [
    {
      type: "input_value",
      name: "COLLECTION_NAME",
      check: "String",
    },
    {
      type: "input_value",
      name: "DOCUMENT_NAME",
      check: "String",
    },
  ],
  message1: "With %1 Do %2",
  args1: [
    {
      type: "field_variable",
      name: "RESPONSE",
      variable: "response",
    },
    {
      type: "input_statement",
      name: "DO",
    },
  ],
  colour: 120,
  previousStatement: null,
  nextStatement: null,
};
const setDataInDB = {
  kind: "block",
  type: "set_data_in_db",
  message0: "Set data in collection %1 in document %2 ",
  args0: [
    {
      type: "input_value",
      name: "COLLECTION_NAME",
      // check: "String",
    },
    {
      type: "input_value",
      name: "DOCUMENT_NAME",
      // check: "String",
    },
  ],
  message1: "to Dict %1",
  args1: [
    {
      type: "input_value",
      name: "DOCUMENT",
    },
  ],
  colour: 120,
  previousStatement: null,
  nextStatement: null,
};

const patchDataInDB = {
  kind: "block",
  type: "patch_data_in_db",
  message0: "Patch data in collection %1 in document %2 ",
  args0: [
    {
      type: "input_value",
      name: "COLLECTION_NAME",
      // check: "String",
    },
    {
      type: "input_value",
      name: "DOCUMENT_NAME",
      // check: "String",
    },
  ],
  message1: "with Dict %1",
  args1: [
    {
      type: "input_value",
      name: "DOCUMENT",
    },
  ],
  colour: 120,
  previousStatement: null,
  nextStatement: null,
};

const deleteDataInDB = {
  kind: "block",
  type: "delete_data_in_db",
  message0: "Delete document %2 in collection %1 ",
  args0: [
    {
      type: "input_value",
      name: "COLLECTION_NAME",
      // check: "String",
    },
    {
      type: "input_value",
      name: "DOCUMENT_NAME",
      // check: "String",
    },
  ],

  colour: 120,
  previousStatement: null,
  nextStatement: null,
};

const print = {
  kind: "block",
  type: "print",
  message0: "Print %1",
  args0: [
    {
      type: "input_value",
      name: "PRINT_VALUE",
    },
  ],
  previousStatement: null,
  nextStatement: null,
  colour: 160,
};

// Create the block definitions for the JSON-only blocks.
// This does not register their definitions with Blockly.
// This file has no side effects!

Blockly.Extensions.register("contextMenu_setParent", function () {
  // `this` refers to the block instance
  var block = this;

  // Modify the context menu options
  block.contextMenu = function (options) {
    // Your custom logic here
    // For example, add or remove items in the context menu

    // Return the modified context menu options
    return options;
  };
});
export const blocks = Blockly.common.createBlockDefinitionsFromJsonArray([
  addText,
  createEmptyObject,
  c1,
  c2,
  addToObject,
  // setObjectVar,
  inObject,
  getKeys,
  // getKeyValue,
  deleteKey,
  forKeyInObject,
  getGameConstant,
  getClientConstant,
  getEntityConstent,
  getPersistentbjectConstant,
  getRoomConstant,
  getEventNameConstant,
  setcfs,
  getMessageStructConstant,
  getSenderClientIdConstant,
  stepBlock,
  clientSentEventBlock,
  gameServerCreatedBlock,
  roomCreatedBlock,
  clientCreatedBlock,
  entityCreatedBlock,
  forRoomsInGame,
  forClientsInRoom,
  forPOinRoom,
  forEntitiesInClient,
  persistentObjectCreated,
  getRoom,
  getClient,
  getEntity,
  getPO,
  getSP,
  getSPS,
  setSP,
  setSPS,
  editObject,
  getEP,
  getEPS,
  setEP,
  setEPS,
  createPO,
  getPOP,
  setPOP,
  destroyPO,
  getDataFromDB,
  setDataInDB,
  patchDataInDB,
  deleteDataInDB,
  print,
]);
