import React, {
  createContext,
  useState,
  useContext,
  useEffect,
  useRef,
} from "react";
import Editor from "@monaco-editor/react";
import { editor } from "monaco-editor";
import {
  Card,
  CardContent,
  Typography,
  Button,
  TextField,
  AppBar,
  IconButton,
  Toolbar,
} from "@material-ui/core";
import axios from "axios";
//import play arrow icon from mui old version
import PlayArrowIcon from "@material-ui/icons/PlayArrow";

import GroupAddIcon from "@material-ui/icons/GroupAdd";
import PersonIcon from "@material-ui/icons/Person";
import AttachMoneyIcon from "@material-ui/icons/AttachMoney";

import CircularProgress from "@material-ui/core/CircularProgress";
//import snackbar
import Snackbar from "@material-ui/core/Snackbar";
import "./App.css";

//navigate
import { useNavigate } from "react-router-dom";
//import firebase
import firebase from "firebase/compat/app";

import { Toaster, toast } from "react-hot-toast";
//import firebase rtdb
import "firebase/compat/database";
import Blockly from "./Blockly.js";

function GIDE() {
  //URL params
  const params = new URLSearchParams(window.location.search);

  const [code, setCode] = useState("");

  return (
    <>
      <Toaster />

      <div
        //className="App"
        style={{
          //backgroundColor: "#FFFFFF",
          display: "flex",
          flexDirection: "column",
          alignItems: "left",
          // justifyContent: "space-evenly",
        }}
      >
        <AppBar
          position="static"
          style={{
            backgroundColor: "#000000",
            height: "7vh",
            width: "100%",
            display: "flex",
            flexDirection: "row",
            alignItems: "center",
            justifyContent: "center",
            //self aligns to the top
            alignSelf: "flex-start",
          }}
        >
          <Typography
            variant="h6"
            style={{
              flexGrow: 1,
              //bold
              fontWeight: 600,
              paddingLeft: "20px",
            }}
          >
            Rocket Networking Server Scripts✨ - Beta Block Editor
          </Typography>

          <Toolbar></Toolbar>
        </AppBar>

        <div
          style={{
            display: "flex",
            flexDirection: "row",
            width: "80%",
            height: "80vh",
            alignSelf: "center",
            backgroundColor: "#1E1E1E",
            color: "#FFF",
            borderRadius: "10px",
            boxShadow: "0px 4px 20px rgba(0, 0, 0, 0.15)",
            padding: "20px",
            marginTop: "30px",
          }}
        >
          <Blockly generatedCode={code} setGeneratedCode={setCode} />

          <Editor
            height="80%"
            maxWidth
            theme="vs-dark"
            language="javascript"
            value={code}
            options={{
              readOnly: true,
              minimap: {
                enabled: false,
              },
            }}
          />
        </div>
      </div>
    </>
  );
}

export default GIDE;
